<template>
    <div class="expense">
        <section class="h-100 w-100 d-flex flex-column ">
            <div class="center d-flex justify-content-md-center align-items-center flex-column flex-grow-1">
                <b-col cols="8" class="mx-auto mt-5 mt-md-0">
                    <b-row>
                      <div class="d-flex flex-column align-items-center mt-5 mt-md-0">
                        <img src="../../../../assets/ic_done_green.png" class="y-icon"/>
                        <p class="mt-2 d-inline-block text">Complete</p>
                      </div>
                    </b-row>

                    <b-row style="margin-top: 35px;">
                      <b-col cols="3"></b-col>
                      <b-col cols="6">
                        <b-button class="button" @click="back">
                          Done
                        </b-button>
                      </b-col>
                      <b-col cols="3"></b-col>
                    </b-row>

                </b-col>
            </div>
        </section>
    </div>
</template>

<script>
    import router from "../../../../app/router/router"

    export default {
        name: "ConfirmApproval",
        props:{
            action:String
        },
        methods:{
            back(){
                router.push("/view-requests")
            },
        },
    }
</script>

<style scoped>
    @import "../../../../commons/css/main.css";

    .expense{

    }

    .y-icon{
        width: 150px;
    }

    .center{
        margin-top: 180px;
    }

    .text{
        font-size: 18px;
    }

    .button{
      width: 100%;
      background: #c82333;
      font-size: 14px;
      padding: 20px;
      color: white;
      border: solid 1px #c82333;
      border-radius: 3px;
    }

    .button:hover{
      background: #721c24;
    }

</style>