import axios from "axios";
import configs from "../../../../app/configurations/configs";

const IP = configs.SERVER_IP;

const state = {
  response:{},
  paginator:{},
  error:{},
  requests:[]
};

const getters = {
  getRequests: (state) => state.requests,
  getRequestsPaginator:(state) => state.paginator,
  getRequestSuccessResponse: (state) => state.response,
  getRequestErrorResponse: (state) => state.error,
};

const actions = {
  async fetchRequests({ commit }) {
    await axios
      .get(IP + "/customerjourney/all?business_id="+ localStorage.getItem("business_id")+
          "&location_id="+localStorage.getItem("location_id"))
      .then((response) => {
        commit("setRequests", response.data);
      })
      .catch((error) => {
        commit("setErrorResponse", error.response);
      });
  },

    async fetchRequestsPaged({ commit }, data) {
        await axios
            .get(IP + "/customerjourney/all?business_id="+ localStorage.getItem("business_id")+
                "&location_id="+localStorage.getItem("location_id")+"&page="+data.page)
            .then((response) => {
                commit("setRequests", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },

  async approveRequest({ commit }, data) {
    await axios
        .get(IP + "/customerjourney/approve/"+data.id+"/"+data.amount)
        .then((response) => {
          commit("setSuccessResponse", response.data);
        })
        .catch((error) => {
          commit("setErrorResponse", error.response);
        });
  },
  async refundRequest({ commit }, data) {
    await axios
        .get(IP + "/customerjourney/refund/"+data.id, data.params)
        .then((response) => {
          commit("setSuccessResponse", response.data);
        })
        .catch((error) => {
          commit("setErrorResponse", error.response);
        });
  },
  async cancelRequest({ commit }, id) {
    await axios
        .get(IP + "/customerjourney/cancel/"+id)
        .then((response) => {
          commit("setSuccessResponse", response.data);
        })
        .catch((error) => {
          commit("setErrorResponse", error.response);
        });
  },
};

const mutations = {
  setRequests: (state, response) => {
    state.requests = response.data;
    state.paginator = response.paginator;
  },
  setSuccessResponse: (state, response) => {
    state.response = response.data;
  },
  setErrorResponse: (state, response) => {
    state.error = response.data;
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
