<template>
    <div class="expense">
        <section class="h-100 w-100 d-flex flex-column ">
            <div class="center d-flex justify-content-md-center align-items-center flex-column flex-grow-1">
                <div class="col-md-8 col-lg-7 mx-auto mt-5 mt-md-0">
                    <div class="d-flex flex-column align-items-center mt-5 mt-md-0">
                        <img src="../../../../assets/ic_done_green.png" class="y-icon"/>
                        <p class="mt-2 d-inline-block text">Giving Points Complete</p>
                    </div>

                  <b-row class="mt-5">
                    <b-col cols="1" sm="1" md="3" lg="3"></b-col>
                    <b-col cols="10" sm="10" md="6" lg="6">
                      <button class="button"
                              type="button"
                              @click="done()">Done</button>
                    </b-col>
                    <b-col cols="1" sm="1" md="3" lg="3"></b-col>
                  </b-row>
                </div>
            </div>
            <b-row class="bottom">
              <b-col  cols="12" class="text-center justify-content-center align-content-center py-2">
                <b-row>
                  <b-col>
                    <span class="footer-text">Powered By</span>
                    <img src="../../../../assets/logo_small_size.png" class="footer-icon"/>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
        </section>
    </div>
</template>

<script>
    import router from "../../../../app/router/router"

    export default {
        name: "GivingPointsComplete",
        methods:{
            done(){
                router.push("/home/give-or-redeem")
            },
        },
    }
</script>

<style scoped>
    @import "../../../../commons/css/main.css";

    .expense{

    }

    .y-icon{
        width: 150px;
    }

    .center{
        margin-top: 180px;
    }

    .text{
        font-size: 16px;
    }

    .button{
      width: 100%;
      height: 60px;
      background: #c82333;
      color: white;
      border: solid 1px #c82333;
      border-radius: 8px;
    }

    .button:hover{
      background: #9c1825;
    }

    .bottom{
      position: fixed;
      bottom: 0;
      width: 100%;
    }

    .footer-icon{
      height: 20px;
      width: 60px;
      margin-left: 10px;
    }

    .footer-text{
      font-size: 14px;
    }

    @media only screen and (max-width: 640px){

    }

    @media only screen and (max-width: 560px){
      .button{
        font-size: 14px;
        height: 40px;
      }

      .y-icon{
        width: 100px;
      }

      .center{
        margin-top: 80px;
      }

      .footer-text{
        font-size: 12px;
      }

    }

    @media only screen and (max-width: 480px){
      .text{
        font-size: 12px;
      }
    }

    @media only screen and (max-width: 380px) {

      .y-icon{
        width: 80px;
      }

      .center{
        margin-top: 120px;
      }

      .text{
        font-size: 11px;
      }
    }

    @media only screen and (max-width: 320px) {
      .center{
        margin-top: 50px;
      }

      .y-icon{
        width: 60px;
      }

      .text{
        font-size: 11px;
      }

      .button{
        font-size: 11px;
        height: 35px;
      }

    }

</style>