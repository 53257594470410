import axios from "axios";
import configs from "../../../app/configurations/configs";

const IP = configs.SERVER_IP;

const state = {
  locations:[],
  error:{}
};

const getters = {
  getLocations: (state) => state.locations,
  getLocationErrorResponse: (state) => state.error,
};

const actions = {
  async fetchLocations({ commit }) {
    await axios
      .get(IP + "/locations/business/"+localStorage.getItem("business_id"))
      .then((response) => {
        //console.log(JSON.stringify(response.data))
        commit("setLocationSuccessResponse", response.data);
      })
      .catch((error) => {
        commit("setLocationErrorResponse", error.response);
      });
  },
};

const mutations = {
  setLocationSuccessResponse: (state, response) => {
    state.locations = response.data;
  },
  setLocationErrorResponse: (state, response) => {
    state.error = response.data;
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
