<template>
    <div>
        <RewardsHeader title=""/>

        <b-row class="m-top">
            <b-col cols="2" sm="4" md="4" lg="4"></b-col>
            <b-col cols="8" sm="4" md="4" lg="4" class="text-center">
                <b-row>
                  <b-col class="text-center justify-content-center">
                    <h3 class="h3 mx-auto bold title">REDEEM</h3>
                  </b-col>
                </b-row>
            </b-col>
            <b-col cols="2" sm="4" md="4" lg="4"></b-col>
        </b-row>
        <!--content-->
        <b-row class="align-content-center m-top">
            <b-col cols="0" sm="0" md="2" lg="2"></b-col>
            <b-col cols="12" md="8" lg="8">
              <b-row class="m-top-3">
                <b-col cols="12" class="text-center justify-content-center">
                  <div class="text-center">
                    <b-row>
                      <b-col class="text-center m-top-2">
                        <div class="points font-weight-bold">{{redeem.points}}</div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="text-center">
                        <div class="points-text">Points</div>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="text-center justify-content-center m-top-big">
                  <span class="h5 title">Make Every Day A Happy Day With A Free</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="text-center justify-content-center py-1">
                  <span class="h5 title">{{ redeem.name }}</span>
                </b-col>
              </b-row>
              <b-row class="form-spacing">
                <b-col cols="2" sm="2" md="3" lg="3"></b-col>
                <b-col cols="8" sm="8" md="6" lg="6">
                  <b-row>
                    <b-col cols="12" class="text-center">
                      <b-overlay :show="loading" rounded="sm">
                        <button class="button"
                                type="button"
                                @click="onSubmit()">Yes</button>
                      </b-overlay>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="2" sm="2" md="3" lg="3"></b-col>
              </b-row>
            </b-col>
            <b-col cols="0" sm="0" md="2" lg="2"></b-col>
        </b-row>

      <b-row class="bottom">
        <b-col  cols="12" class="text-center justify-content-center align-content-center py-2">
          <b-row>
            <b-col>
              <span class="footer-text">Powered By</span>
              <img src="../../../../assets/logo_small_size.png" class="footer-icon"/>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import RewardsHeader from "../components/RewardsHeader";
    import router from "../../../../app/router/router";

    export default {
        name: "RedeemReward",
        components: {RewardsHeader},
        data(){
            return{
              customer:JSON.parse(localStorage.getItem("customer")),
              redeem:JSON.parse(localStorage.getItem("redeem")),
              receipt:null,
              loading:false
            }
        },
        methods:{
            ...mapActions(["redeemReward"]),
            onSubmit(){
              this.loading = true
              let data = {
                customer_id: this.customer.id,
                points: this.redeem.points,
                reward_id: this.redeem.reward_id,
                transaction_type: "Redeem"
              }
              localStorage.setItem("redeem_data", JSON.stringify(data))

              if(localStorage.getItem("business_id") === "1d4524b4-c484-4f59-b571-5933dfdcc2c8" || localStorage.getItem("business_id") === "d6735c0e-9b41-4333-b807-47cfa8000166"){
                this.redeemReward(data)
              }else{
                router.push("/complete/redeem/receipt-no")
              }
            },
        },
        computed: {
            ...mapGetters([
                "getRedeemRewardSuccessResponse",
                "getRedeemRewardErrorResponse",
            ]),
        },
        watch:{
            getRedeemRewardSuccessResponse(){
              this.loading = false
              if(localStorage.getItem("business_id") === "1d4524b4-c484-4f59-b571-5933dfdcc2c8" || localStorage.getItem("business_id") === "d6735c0e-9b41-4333-b807-47cfa8000166"){
                router.push("/complete/redeem-otp")
              }else{
                router.push("/complete/redeem/receipt-no")
              }

            },
            getRedeemRewardErrorResponse(){
              this.loading = false
              alert("Server Error")
            }
        }
    }
</script>

<style scoped>
    @import "../../../../commons/css/main.css";

    .bold{
        font-weight: bold;
    }

    .title{
      margin-top: 80px;
    }

    .points{
      font-size: 40px;
      color: #A6C34C;
      margin: 0;
      line-height: 35px;
    }

    .points-text{
      color: #A6C34C;
      margin: 0;
      font-size: 16px;
    }

    .button{
      width: 100%;
      height: 60px;
      background: #c82333;
      color: white;
      border: solid 1px #c82333;
      border-radius: 8px;
    }

    .button:hover{
      background: #9c1825;
    }

    .outline-button{
      background: #fff;
      color: #c82333;
      padding: 5px;
      font-weight: bold;
      border: solid 2px #c82333;
      border-radius: 6px;
    }

    .outline-button:hover{
      background: #c82333;
      color: #fff;
    }

    .form-spacing{
      margin-top: 60px;
    }

    .bottom{
      position: fixed;
      bottom: 0;
      width: 100%;
    }

    .footer-icon{
      height: 20px;
      width: 60px;
      margin-left: 10px;
    }

    .footer-text{
      font-size: 14px;
    }

    .m-top{
      margin-top: 10px;
    }

    .m-top-2{
      margin-top: 20px;
    }

    .m-top-3{
      margin-top: 30px;
    }

    .m-top-big{
      margin-top: 30px;
    }

    @media only screen and (max-width: 640px){

      .points{
        font-size: 60px;
        line-height: 35px;
      }

      .points-text{
        font-size: 20px;
      }

      .m-top-big{
        margin-top: 80px;
      }

    }

    @media only screen and (max-width: 560px){

      .form-spacing{
        margin-top: 40px;
      }

      .button{
        height: 40px;
        font-size: 14px;
      }

    }

    @media only screen and (max-width: 480px){
      .points{
        font-size: 40px;
        line-height: 35px;
      }

      .points-text{
        font-size: 16px;
      }

      .m-top-big{
        margin-top: 10px;
      }

      .title{
        font-size: 14px;
      }

      .m-top{
        margin-top: 5px;
      }

      .m-top-2{
        margin-top: 15px;
      }

      .m-top-3{
        margin-top: 20px;
      }
    }

    @media only screen and (max-width: 380px) {
      .points{
        font-size: 60px;
        line-height: 35px;
      }

      .m-top-big{
        margin-top: 40px;
      }

      .title{
        font-size: 16px;
      }

      .m-top{
        margin-top: 5px;
      }

      .m-top-2{
        margin-top: 15px;
      }

      .m-top-3{
        margin-top: 20px;
      }

    }

    @media only screen and (max-width: 320px) {

      .points{
        font-size: 40px;
        line-height: 35px;
      }

      .points-text{
        font-size: 16px;
      }

      .m-top-big{
        margin-top: 20px;
      }

      .title{
        font-size: 14px;
      }

      .m-top-2{
        margin-top: 5px;
      }

      .m-top-3{
        margin-top: 15px;
      }

      .button{
        height: 35px;
        font-size: 12px;
      }

    }

</style>