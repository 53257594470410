<template>
  <div class="home">
    <b-row>
      <b-sidebar id="sidebar" ref="mySidebar" title=""  shadow
                 aria-labelledby="sidebar-no-header-title"
                 no-header
                 backdrop-variant="dark"
                 backdrop>

        <template #footer="{ }">

          <div class="bg-dark text-light align-items-center px-3 py-2 ft">
            <b-row>
              <div class="d-flex">
                <div class="logo">
                  <img src="../../../assets/logo.png" alt="logo" class="dp">
                </div>
                <div class="shop-details">
                  <span class="font-weight-bold">{{name}}</span><br/>
                  <span class="small">{{location}}</span>
                </div>
              </div>
            </b-row>
            <b-row>
              <div class="logout">
                <button class="logout-btn" @click="logout()">Logout</button>
              </div>
            </b-row>
          </div>
        </template>

        <div class="px-3 py-2 side-menu bg-dark">
          <div class="y-menu-list-group">
            <a @click="loadPage(path)" :class="[currentPage == '/home/give-or-redeem' ? 'active':'y-menu-list-group-item']">
              Give & Redeem Points
            </a>

            <a @click="loadPage('/home/requests')" :class="[currentPage == '/home/requests' ? 'active':'y-menu-list-group-item']">
              Pending Requests
            </a>

            <a @click="loadPage('/home/apps')"  :class="[currentPage == '/home/apps' ? 'active':'y-menu-list-group-item']">
              Apps
            </a>
          </div>
        </div>
      </b-sidebar>
    </b-row>

    <b-row>
      <b-col cols="12" sm="12" md="6" lg="6"  class="left-menu-data">
        <b-row>
          <b-col cols="2" sm="2" md="1" lg="1" style="padding-right: 0;" class="text-center">
            <img v-b-toggle.sidebar src="../../../assets/ic_grid.svg" class="y-menu-icon"/>
          </b-col>
          <b-col cols="10" sm="10" md="11" lg="11" style="padding-left: 0;">
            <div class="heading vertical-center">
              <span class="section-title pop">Pending Requests</span>
            </div>
          </b-col>
        </b-row>

        <b-row class="business-details">
          <b-row>
            <b-col cols="1" sm="1" md="1" lg="1"> </b-col>
            <b-col cols="3" sm="3" md="3" lg="3" class="text-center">
              <div>
                <img src="../../../assets/business_dummy.png" class="badger"/>
              </div>
            </b-col>
            <b-col cols="8" sm="8" md="7" lg="7" class="user-details top-padding">
              <b-row>
                <b-col col md="7" lg="8">
                  <b-row>
                    <b-col>
                      <h1 class="h4 font-weight-bold pop name">{{name}}</h1>
                    </b-col>
                  </b-row>
                  <b-row style="margin-top: 3px; margin-left: 0">
                    <b-col cols="10" style="background: #f8c3c7; height: 1px;"></b-col>
                  </b-row>
                  <b-row style="margin-top: 10px;">
                    <b-col>
                      <div class="pop location">{{location}}</div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="0" sm="0" md="1" lg="1"> </b-col>
          </b-row>
        </b-row>

      </b-col>
      <b-col cols="12" sm="12" md="6" lg="6">
        <!--content-->
        <b-row class="content">
          <b-col cols="12">
            <b-row>
              <b-col cols="1" sm="1" md="2" lg="2"></b-col>
<!--              <b-col cols="4" sm="4" md="3" lg="3" class="service" @click="goToSearch">-->
<!--                <b-row >-->
<!--                  <b-col cols="12" class="text-center">-->
<!--                    <img src="../../../assets/services/service_search.svg" class="image">-->
<!--                  </b-col>-->
<!--                </b-row>-->
<!--                <b-row>-->
<!--                  <b-col cols="12" class="text-center justify-content-center align-content-center" >-->
<!--                    <span class="font-weight-bold title">Search</span>-->
<!--                  </b-col>-->
<!--                </b-row>-->
<!--              </b-col>-->
<!--              <b-col cols="1" sm="1" md="2" lg="2"></b-col>-->
              <b-col cols="4" sm="4" md="3" lg="3" class="service" @click="goToViewRequests">
                <b-row>
                  <b-col cols="12" class="text-center">
                    <img src="../../../assets/services/service_view_requests.svg" class="image">
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" class="text-center justify-content-center align-content-center" >
                    <span class="font-weight-bold title">View Requests</span>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="1" sm="1" md="2" lg="2"></b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import router from "../../../app/router/router";
export default {
  name: "Requests",
  data(){
    return{
      activeClass:'active',
      currentPage: this.$route.fullPath,
      title: this.titleParser(this.$route.fullPath),
      name: JSON.parse(localStorage.getItem("business")).name,
      location: localStorage.getItem("location_name"),
      path: "/home/give-or-redeem"
    }
  },
  mounted() {
    if(localStorage.getItem("token") == null){
      router.push("/")
    }
  },
  methods:{
    closeSideMenu(){
      this.$refs.mySidebar.hide();
    },

    loadPage(route){
      router.push(route)
    },
    titleParser(route){
      var name = route.split("/")[2]
      return this.capitalizeFirstLetter(name)
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    goToSearch(){
      router.push("/search-receipt")
    },
    goToViewRequests(){
      router.push("/view-requests")
    },
    logout(){
      localStorage.clear()
      router.push("/")
    }
  },
};
</script>

<style scoped>
@import "../../../commons/css/main.css";
@import "../../../commons/css/menu.css";
@import "../../../commons/css/utils.css";

.vertical-center {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.content{
  margin-top: 60px;
}

.sec-heading{
  margin-top: 10px;
  display: none;
}

.business-details{
  margin-top: 100px;
}

.service{
  border-radius: 6px;
  padding: 10px;
  box-shadow: 2px 4px 5px 3px #dedede;
}

.service:hover{
  box-shadow: 3px 5px 5px 4px #d5d5d5;
}

.image{
  width: 150px;
  height: 170px;
  margin-bottom: 20px;
}

.title{
  font-size: 12px;
}

@media only screen and (max-width: 380px) {

  .image{
    width: 100%;
    height: 90px;
  }

  .title{
    font-size: 11px;
  }

  .service{
    padding: 5px;
  }

  .name{
    font-size: 14px;
  }

  .location{
    font-size: 14px;
  }


}

@media only screen and (max-width: 320px) {

}

</style>

