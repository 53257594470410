import axios from "axios";
import configs from "../../../../app/configurations/configs";

const IP = configs.SERVER_IP;

const state = {
  response:{},
  error:{},
  history:[]
};

const getters = {
    getPointsHistory: (state) => state.history,
    getCheckoutSuccessResponse: (state) => state.response,
    getCheckoutErrorResponse: (state) => state.error,
    getCustomerSuccessResponse: (state) => state.response,
    getCustomerErrorResponse: (state) => state.error,
};

const actions = {
    async checkout({ commit }, phone) {
        await axios
            .get(IP + "/customers/"+phone+"?business_id="+localStorage.getItem("business_id"))
            .then((response) => {
                console.log(JSON.stringify(response.data))
                commit("setSuccessResponse", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },
    async createCustomer({ commit }, data) {
        await axios
            .post(IP + "/customers",
                {
                    business_id: localStorage.getItem("business_id"),
                    name: data.fname +" "+data.lname,
                    phone_number: data.phone,
                    email: data.email,
                    address: data.address,
                    gender: data.gender,
                    date_of_birth:  data.dob
                }
            )
            .then((response) => {
                console.log(JSON.stringify(response.data))
                commit("setSuccessResponse", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },
    async fetchPointsHistory({ commit }, data) {
        await axios
            .get(IP + "/customers/pointshistory/"+data.id+"?business_id="+localStorage.getItem("business_id"))
            .then((response) => {
                commit("setHistory", response.data);
            })
    },
};

const mutations = {
    setSuccessResponse: (state, response) => {
        state.response = response.data;
    },
    setErrorResponse: (state, response) => {
        state.error = response.data;
    },
    setHistory: (state, response) => {
        state.history = response.data.data;
    },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
