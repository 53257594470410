<template>
    <div>
        <b-row class="header">
          <b-col cols="3" sm="3" md="1" lg="1">
            <img @click="back()" src="../../../../assets/ic_navigate_back.png" class="y-icon" alt="back">
          </b-col>
          <b-col cols="9" sm="9" md="11" lg="11">

          </b-col>
        </b-row>

      <b-row>
        <b-col cols="2" sm="2" md="4" lg="4"></b-col>
        <b-col cols="5" sm="5" md="3" lg="3" class="heading">
          <b-row>
            <b-col class="text-center justify-content-center">
              <h3 class="h3 mx-auto bold title">GIVE POINTS</h3>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-center justify-content-center">
              <button class="outline-button" @click="goToHistory">View History</button>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3" sm="3" md="3" lg="3">
          <img src="../../../../assets/services/service_give_points.svg" class="banner"/>
        </b-col>
        <b-col cols="2" sm="2" md="2" lg="2"></b-col>
      </b-row>
        <!--content-->
        <b-row>
            <b-col cols="1"></b-col>
            <b-col cols="10">
              <b-row>
                <b-col cols="12" class="text-center justify-content-center">
                  <p class="h5 title-2">Customer's Total Bill</p>
                </b-col>
              </b-row>
              <b-row class="form-spacing">
                <b-col cols="1" sm="1" md="4" lg="4"></b-col>
                <b-col cols="10" sm="10" md="4" lg="4">
                  <b-row class="align-content-center fill-width-parent">
                    <b-col>
                      <input type="text"
                             class="input text-center"
                             v-model="raw_amount"
                             placeholder="Total Bill"/>
                    </b-col>
                  </b-row>

                  <b-row class="mt-5" style="width: 100%">
                    <b-col cols="12" class="text-center">
                      <b-overlay :show="loading" rounded="sm">
                        <button class="button"
                                type="button"
                                @click="onSubmit()">Give</button>
                      </b-overlay>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="1" sm="1" md="4" lg="4"></b-col>
              </b-row>
            </b-col>
            <b-col cols="1"></b-col>
        </b-row>

        <b-row class="bottom">
          <b-col  cols="12" class="text-center justify-content-center align-content-center py-2">
            <b-row>
              <b-col>
                <span class="footer-text">Powered By</span>
                <img src="../../../../assets/logo_small_size.png" class="footer-icon"/>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import router from "../../../../app/router/router";

    export default {
        name: "BillGivePoints",
        data(){
            return{
              customer:JSON.parse(localStorage.getItem("customer")),
              amount:null,
              raw_amount:null,
              loading:false
            }
        },
        methods:{
            ...mapActions(["fetchRewards", "givePoints"]),
            onSubmit(){
              this.loading = true
              let data = {
                customer_id:this.customer.id,
                amount:this.amount
              }
              this.givePoints(data)
            },
            goToHistory(){
              router.push("/points-history")
            },
            back(){
              window.history.back();
            }
        },
        computed: {
            ...mapGetters([
                "getPointsSuccessResponse",
                "getPointsErrorResponse",
            ]),
            getAmount(){
              return this.raw_amount
            }
        },
        watch:{
            getPointsSuccessResponse(){
              this.loading = false
              router.push("/complete/points")
            },
            getPointsErrorResponse(data){
              this.loading = false
              alert(data.message)
            },
            getAmount(data){
              this.amount =  data.replaceAll(",", "")
              this.raw_amount = Number(this.amount).toLocaleString()
            }
        }
    }
</script>

<style scoped>
    @import "../../../../commons/css/main.css";

    .header{
      width: 100%;
      padding: 5px 10px;
    }

    .heading{
      margin-top: 40px;
    }

    .y-icon{
      padding: 5px;
      width: 45px;
      margin: 10px;
    }

    .y-icon:hover{
      padding: 6px;
      background: #f3f3f3;
      border-radius: 6px;
    }

    .banner{
      width: 70%;
    }

    .bold{
        font-weight: bold;
    }

    .input{
      width: 100%;
      height: 60px;
      font-size: 25px;
      font-weight: bold;
      padding: 0;
      outline: none;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom: dashed 1px #636363;
    }

    input:hover {
      border-top: 0;
      border-left: 0;
      border-right: 0;
      outline: none;
    }

    input:focus{
      border-top: 0;
      border-left: 0;
      border-right: 0;
      outline: none;
    }

    .button{
      width: 100%;
      height: 60px;
      background: #c82333;
      color: white;
      border: solid 1px #c82333;
      border-radius: 8px;
    }

    .button:hover{
      background: #9c1825;
    }

    .outline-button{
      background: #fff;
      color: #c82333;
      padding: 5px;
      font-weight: bold;
      border: solid 2px #c82333;
      border-radius: 6px;
    }

    .outline-button:hover{
      background: #c82333;
      color: #fff;
    }

    .form-spacing{
      margin-top: 20px;
    }

    .bottom{
      position: fixed;
      bottom: 0;
      width: 100%;
    }

    .footer-icon{
      height: 20px;
      width: 60px;
      margin-left: 10px;
    }

    .footer-text{
      font-size: 14px;
    }

    @media only screen and (max-width: 640px){
      .banner{
        width: 180px;
        margin-top: 10px;
      }

      .title-2{
        font-size: 14px;
        margin-top: 40px;
      }

      .input{
        height: 50px;
        font-size: 22px;
      }

      .outline-button{
        font-size: 14px;
        border: solid 1px #c82333;
      }
    }

    @media only screen and (max-width: 560px){
      .title{
        font-size: 16px;
      }

      .title-2{
        font-size: 16px;
        margin-top: 10px;
      }

      .banner{
        width: 150px;
      }
      .y-icon{
        padding: 5px;
        width: 38px;
        margin-top: 14px;
      }

      .title-2{
        font-size: 12px;
        margin-top: 40px;
      }

    }

    @media only screen and (max-width: 480px){
      .title{
        font-size: 12px;
      }

      .banner{
        width: 120px;
      }
      .outline-button{
        font-size: 12px;
        border: solid 1px #c82333;
      }
      .footer-icon{
        height: 15px;
        width: 35px;
        margin-left: 6px;
      }

      .footer-text{
        font-size: 11px;
      }

      .input{
        height: 40px;
        font-size: 18px;
      }
    }

    @media only screen and (max-width: 380px) {
      .title{
        font-size: 14px;
      }
      .y-icon{
        padding: 5px;
        width: 32px;
        margin-top: 14px;
      }

      .button{
        height: 50px;
        font-size: 12px;
      }
    }

    @media only screen and (max-width: 320px) {

    }
</style>