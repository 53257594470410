import axios from "axios";
import configs from "../../../../app/configurations/configs";

const IP = configs.SERVER_IP;

const state = {
  response:{},
  error:{},
  journeys:[],
  categories:[],
  category_items:[],
  serviceResults:[]
};

const getters = {
  getCategories: (state) => state.categories,
  getCategoryItems: (state) => state.category_items,
  getJourneys: (state) => state.journeys,
  getServiceResults: (state) => state.serviceResults,
  getJourneySuccessResponse: (state) => state.response,
  getJourneyErrorResponse: (state) => state.error,
};

const actions = {
  async fetchJourneys({ commit }, cid) {
    await axios
      .get(IP + "/customerjourney/customer?business_id="+localStorage.getItem("business_id")+"&location="+localStorage.getItem("location_id")+"&customer_id="+cid)
      .then((response) => {
        commit("setJourneys", response.data);
      })
      .catch((error) => {
        commit("setErrorResponse", error.response);
      });
  },
  async searchServices({ commit }, text) {
    await axios
        .get(IP + "/items/search?business_id="+localStorage.getItem("business_id")+"&location_id="+localStorage.getItem("location_id")+"&q="+text)
        .then((response) => {
          commit("setServiceResults", response.data);
        })
        .catch((error) => {
          commit("setErrorResponse", error.response);
        });
  },
    searchServicesNull({ commit }) {
        commit("setServiceResultsNull", []);
    },

  async fetchApprovedJourneys({ commit }, cid) {
    await axios
        .get(IP + "/customerjourney/customer?business_id="+localStorage.getItem("business_id")+
            "&customer_id="+cid+"&journey_status=Approved")
        .then((response) => {
          commit("setJourneys", response.data);
        })
        .catch((error) => {
          commit("setErrorResponse", error.response);
        });
  },

  async addJourney({ commit }, data) {
    const params = new URLSearchParams();
    params.append('business_id', localStorage.getItem("business_id"));
    params.append('location_id', data.location_id);
    params.append('customer_id', data.customer_id);
    params.append('receipt_no', data.receipt_no);
    params.append('service_name', data.service_name);
    params.append('service_details', JSON.stringify(data.items));
    await axios
        .post(IP + "/customerjourney", params)
        .then((response) => {
          console.log(JSON.stringify(response.data))
          commit("setSuccessResponse", response.data);
        })
        .catch((error) => {
          commit("setErrorResponse", error.response);
        });
  },

  async fetchCategories({ commit }) {
    await axios
        .get(IP + "/category/business/"+localStorage.getItem("business_id")+"?location="+localStorage.getItem("location_id"))
        .then((response) => {
          commit("setCategories", response.data);
        })
        .catch((error) => {
          commit("setErrorResponse", error.response);
        });
  },
  async fetchCategoryItems({ commit}, id) {
    await axios
        .get(IP + "/items?category_id="+id+"&location_id="+localStorage.getItem("location_id")+"&business_id="+localStorage.getItem("business_id"))
        .then((response) => {
          commit("setCategoryItems", response.data);
        })
        .catch((error) => {
          commit("setErrorResponse", error.response);
        });
  },
};

const mutations = {
  setCategories: (state, response) => {
    state.categories = response.data;
  },
  setCategoryItems: (state, response) => {
    state.category_items = response.data;
  },
  setSuccessResponse: (state, response) => {
    state.response = response.data;
  },
  setErrorResponse: (state, response) => {
    state.error = response.data;
  },
  setJourneys: (state, response) => {
    state.journeys = response.data;
  },
  setServiceResults: (state, response) => {
    state.serviceResults = response.data;
  },
    setServiceResultsNull: (state) => {
        state.serviceResults = [];
    },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
