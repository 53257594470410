<template>
  <div class="home">

    <b-row>
      <b-col cols="12" sm="12" md="6" lg="6" class="left-menu-data">
        <b-row>
          <b-col cols="3" sm="3" md="4" lg="4" xl="4">
            <img src="../../../assets/ic_navigate_back_white.png" @click="back" class="y-menu-icon"/>
          </b-col>
          <b-col cols="9" sm="9" md="8" lg="8" xl="8"></b-col>
        </b-row>

        <b-row class="business-details">
          <b-row>
            <b-col cols="1" sm="1" md="1" lg="1"> </b-col>
            <b-col cols="3" sm="3" md="3" lg="3" class="text-center">
              <div>
                <img src="../../../assets/user_dummy.png" class="badger"/>
              </div>
              <div>
                <b-row>
                  <b-col class="text-center top-padding">
                    <div class="points font-weight-bold" v-if="customer.details != null">{{customer.details.current_points}}</div>
                    <div class="points font-weight-bold" v-else>0</div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="text-center">
                    <div class="point-text">Points Earned</div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <b-col cols="7" sm="7" md="7" lg="7" class="user-details">
              <b-row>
                <b-col>
                  <b-row>
                    <b-col>
                      <h1 class="h4 font-weight-bold pop name">{{customer.name}}</h1>
                    </b-col>
                  </b-row>
                  <b-row style="margin-top: 1px; margin-left: 0">
                    <b-col cols="10" style="background: #f8c3c7; height: 1px;"></b-col>
                  </b-row>
                  <b-row >
                    <b-col cols="4">
                      <span class="point-text font-weight-bold">Last Seen:</span>
                    </b-col>
                    <b-col cols="7">
                      <span class="point-text" v-if="customer.visits != null">{{formatDate(customer.visits[0])}}</span>
                      <span class="point-text" v-else>N/A</span>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="4">
                      <span class="point-text font-weight-bold">Gender:</span>
                    </b-col>
                    <b-col cols="7">
                      <span class="point-text">{{customer.gender}}</span>
                    </b-col>
                  </b-row>
                  <b-row >
                    <b-col cols="4">
                      <span class="point-text font-weight-bold">ID:</span>
                    </b-col>
                    <b-col cols="7">
                      <span class="point-text">{{customer.customer_id}}</span>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="1" sm="1" md="1" lg="1"> </b-col>
          </b-row>
        </b-row>

        <b-row class="bottom">
          <b-col  cols="10" sm="10" md="5" lg="5">

          </b-col>
          <b-col  cols="2" sm="2" md="6" lg="6">
            <img src="../../../assets/redeems_banner.png" class="banner"/>
          </b-col>
          <b-col  cols="0" sm="0" md="1" lg="1"></b-col>
        </b-row>

      </b-col>

      <b-col cols="12" sm="12" md="6" lg="6" class="right-customer-data">
        <!--content-->
        <b-row class="content">
          <b-col cols="12">
            <b-row class="line">
              <b-col cols="1"></b-col>
              <b-col cols="4" class="service" @click="goToGivePoints">
                <b-row >
                  <b-col cols="12" class="text-center">
                    <img src="../../../assets/services/service_give_points.svg" class="image">
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" class="text-center justify-content-center align-content-center" >
                    <span class="font-weight-bold title">Give Points</span>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="2"></b-col>
              <b-col cols="4" class="service" @click="goToRewards">
                <b-row>
                  <b-col cols="12" class="text-center">
                    <img src="../../../assets/services/service_rewards.svg" class="image">
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" class="text-center justify-content-center align-content-center" >
                    <span class="font-weight-bold title">Rewards</span>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="1"></b-col>
            </b-row>
            <b-row class="line" >
              <b-col cols="1"></b-col>
              <b-col cols="4" class="service"
                     v-if="business_id == '320cd07a-40f6-4446-aabd-8c8e2b66c33c' || business_id == '279bfc07-6484-4df4-88b3-32e8b7cae35d'"
                     @click="goToCustomerJourney">
                <b-row >
                  <b-col cols="12" class="text-center">
                    <img src="../../../assets/services/service_customer_journey.svg" class="image">
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" class="text-center justify-content-center align-content-center" >
                    <span class="font-weight-bold title">Customer Journey</span>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="2"></b-col>
              <b-col cols="4" class="service" style="display: none">
                <b-row>
                  <b-col cols="12" class="text-center">
                    <img src="../../../assets/services/service_feedback.svg" class="image">
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" class="text-center justify-content-center align-content-center" >
                    <span class="font-weight-bold title">Feedback</span>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="1"></b-col>
            </b-row>
            <b-row class="line" style="display: none">
              <b-col cols="1"></b-col>
              <b-col cols="4" class="service">
                <b-row >
                  <b-col cols="12" class="text-center">
                    <img src="../../../assets/services/service_partnerships.svg" class="image">
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" class="text-center justify-content-center align-content-center" >
                    <span class="font-weight-bold title">Partnerships</span>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="7"></b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import moment from "moment"
import router from "../../../app/router/router";
export default {
  name: "Customer",
  data(){
    return{
      customer: null,
      business_id: localStorage.getItem("business_id"),
    }
  },
  created() {
    this.customer = JSON.parse(localStorage.getItem("customer"))
  },
  mounted() {

  },
  methods:{
    back(){
      window.history.back()
    },
    formatDate(date){
      return moment(date).format("DD-MM-YYYY")
    },
    goToGivePoints(){
      router.push("/give-points")
      router.go()
    },
    goToRewards(){
      router.push("/rewards")
      router.go()
    },
    goToCustomerJourney(){
      router.push("/customer-journey")
      router.go()
    }
  },
};
</script>

<style scoped>
@import "../../../commons/css/main.css";
@import "../../../commons/css/menu.css";
@import "../../../commons/css/utils.css";

.content{
  margin-top: 60px;
}

.business-details{
  margin-top: 50px;
}

.top-padding{
  margin-top: 20px;
}

.service{
  border-radius: 6px;
  padding: 10px;
  box-shadow: 2px 4px 5px 3px #dedede;
}

.service:hover{
  box-shadow: 3px 5px 5px 4px #d5d5d5;
}

.image{
  width: 150px;
  height: 170px;
  margin-bottom: 20px;
}

.title{
  font-size: 14px;
}

.line{
  margin-top: 20px;
  margin-bottom: 20px;
}

.points{
  font-size: 60px;
  color: white;
  margin: 0;
  line-height: 55px;
}

.point-text{
  color: white;
  font-size: 12px;
}

.left-customer-data{
  position: fixed;
  left: 0;
  height: 100vh;
  background: #bd2130;
}

.right-customer-data{
  position: fixed;
  right: 0;
  overflow-y: scroll;
  height: calc(100% - 5px);
}

.bottom{
  position: fixed;
  bottom: 0;
  width: 50%;
}

@media only screen and (max-width: 640px){
  .content{
    margin-top: 10px;
  }

  .right-customer-data{
    position: relative;
  }

  .bottom{
    position: relative;
  }

  .banner{
    width: 160px;
    margin-left: 70px;
  }

}

@media only screen and (max-width: 560px){
  .content{
    margin-top: 10px;
  }

  .image{
    width: 100%;
    height: 90px;
  }

  .points{
    font-size: 40px;
    line-height: 35px;
  }

  .point-text{
    color: white;
    font-size: 10px;
  }

  .name{
    font-size: 14px;
  }

  .business-details{
    margin-top: 20px;
  }

  .banner{
    width: 140px;
  }

  .top-padding{
    margin-top: 2px;
  }

  .title{
    font-size: 11px;
  }

}

@media only screen and (max-width: 480px){
  .banner{
    width: 125px;
  }

}

@media only screen and (max-width: 380px) {

  .banner{
    width: 150px;
  }

  .content{
    margin-top: 10px;
  }

}

@media only screen and (max-width: 320px) {

  .content{
    margin-top: 5px;
  }

  .image{
    width: 100%;
    height: 70px;
  }

  .points{
    font-size: 22px;
    line-height: 22px;
  }

  .point-text{
    color: white;
    font-size: 6px;
  }

  .name{
    font-size: 12px;
  }

  .business-details{
    margin-top: 1px;
  }

  .banner{
    width: 110px;
  }

  .top-padding{
    margin-top: 1px;
  }

  .title{
    font-size: 10px;
  }

}

</style>

