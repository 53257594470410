<template>
  <div class="home">
    <b-row>
      <b-sidebar id="sidebar" ref="mySidebar" title=""  shadow
                 aria-labelledby="sidebar-no-header-title"
                 no-header
                 backdrop-variant="dark"
                 backdrop>

        <template #footer="{ }">

          <div class="bg-dark text-light align-items-center px-3 py-2 ft">
            <b-row>
              <div class="d-flex">
                <div class="logo">
                  <img src="../../../assets/logo.png" alt="logo" class="dp">
                </div>
                <div class="shop-details">
                  <span class="font-weight-bold">{{name}}</span><br/>
                  <span class="small">{{location}}</span>
                </div>
              </div>
            </b-row>
            <b-row>
              <div class="logout">
                <button class="logout-btn" @click="logout()">Logout</button>
              </div>
            </b-row>
          </div>
        </template>

        <div class="px-3 py-2 side-menu bg-dark">
          <div class="y-menu-list-group">
            <a @click="loadPage(path)" :class="[currentPage == '/home/give-or-redeem' ? 'active':'y-menu-list-group-item']">
              Give & Redeem Points
            </a>
            <a v-if="business_id == '320cd07a-40f6-4446-aabd-8c8e2b66c33c' || business_id == '279bfc07-6484-4df4-88b3-32e8b7cae35d'"
               @click="loadPage('/home/requests')" :class="[currentPage == '/home/requests' ? 'active':'y-menu-list-group-item']">
              Pending Requests
            </a>

            <a @click="loadPage('/home/apps')"  :class="[currentPage == '/home/apps' ? 'active':'y-menu-list-group-item']">
              Apps
            </a>
          </div>
        </div>
      </b-sidebar>
    </b-row>

    <b-row>
      <b-col cols="12" sm="12" md="12" lg="6" class="left-menu-data">
        <b-row>
          <b-col cols="2" sm="2" md="1" lg="1" style="padding-right: 0;" class="text-center">
            <img v-b-toggle.sidebar src="../../../assets/ic_grid.svg" class="y-menu-icon"/>
          </b-col>
          <b-col cols="10" sm="10" md="11" lg="11" style="padding-left: 0;">
            <div class="heading vertical-center">
              <span class="section-title pop">Give & Redeem Points</span>
            </div>
          </b-col>
        </b-row>

        <b-row class="business-details">
          <b-row>
            <b-col cols="1" sm="1" md="1" lg="1"> </b-col>
            <b-col cols="3" sm="3" md="3" lg="3" class="text-center">
              <div>
                <img src="../../../assets/business_dummy.png" class="badger"/>
              </div>
            </b-col>
            <b-col cols="8" sm="8" md="7" lg="7" class="user-details top-padding">
              <b-row>
                <b-col col md="7" lg="8">
                  <b-row>
                    <b-col>
                      <h1 class="h4 font-weight-bold pop name">{{name}}</h1>
                    </b-col>
                  </b-row>
                  <b-row style="margin-left: 0">
                    <b-col cols="10" style="background: #f8c3c7; height: 1px;"></b-col>
                  </b-row>
                  <b-row style="margin-top: 10px;">
                    <b-col>
                      <div class="pop location">{{location}}</div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="0" sm="0" md="1" lg="1"> </b-col>
          </b-row>
        </b-row>

        <b-row class="bottom">
          <b-col  cols="6" sm="6" md="6" lg="5"></b-col>
          <b-col  cols="5" sm="5" md="6" lg="6">
            <img src="../../../assets/redeems_banner.png" class="banner"/>
          </b-col>
          <b-col  cols="1" sm="1" md="0" lg="1"></b-col>
        </b-row>

      </b-col>
      <b-col cols="12" sm="12" md="12" lg="6">
        <b-row class="content">
          <b-col cols="12" sm="12" md="12" lg="12">
            <b-row>
              <b-col cols="12" class="text-center">
                <p class="text-title">Enter Customers Phone Number</p>
              </b-col>
            </b-row>
            <b-row class="form-spacing">
              <b-col cols="1" sm="2" md="3" lg="3"></b-col>
              <b-col cols="10" sm="8" md="6" lg="6">
                <b-row class="align-content-center fill-width-parent">
                  <b-col>
                    <input type="text"
                           class="input text-center"
                           v-model="phone"
                           placeholder="Phone Number"/>
                  </b-col>
                </b-row>

                <b-row class="form-spacing" style="width: 100%">
                  <b-col cols="12" class="text-center">
                    <b-overlay :show="loading" rounded="sm">
                      <button class="button" type="button" @click="onSubmit()">Enter</button>
                    </b-overlay>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="1" sm="2" md="3" lg="3"></b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="bottom-footer">
          <b-col cols="1"></b-col>
          <b-col  cols="10" class="text-center justify-content-center align-content-center py-2">
            <span class="footer-text">Powered By</span>
            <img src="../../../assets/logo_small_size.png" class="footer-icon"/>
          </b-col>
          <b-col cols="1"></b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import router from "../../../app/router/router";
export default {
  name: "GiveOrRedeem",
  data(){
    return{
      activeClass:'active',
      currentPage: this.$route.fullPath,
      title: this.titleParser(this.$route.fullPath),
      name: JSON.parse(localStorage.getItem("business")).name,
      business_id: localStorage.getItem("business_id"),
      location: localStorage.getItem("location_name"),
      path: "/home/give-or-redeem",
      phone:null,
      loading: false
    }
  },
  mounted() {
    if(localStorage.getItem("token") == null){
      router.push("/")
    }
  },
  methods:{
    ...mapActions(["checkout"]),
    closeSideMenu(){
      this.$refs.mySidebar.hide();
    },
    loadPage(route){
      router.push(route)
    },
    titleParser(route){
      var name = route.split("/")[2]
      return this.capitalizeFirstLetter(name)
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    onSubmit() {
      if(this.phone != null){
        this.loading = true
        this.checkout(this.phone)
      }
    },
    logout(){
      localStorage.clear()
      router.push("/")
    }
  },
  computed: {
    ...mapGetters([
      "getCheckoutSuccessResponse",
      "getCheckoutErrorResponse",
    ]),
  },
  watch:{
    getCheckoutSuccessResponse(data){
      this.loading = false
      localStorage.setItem("customer", JSON.stringify(data))
      router.push("/customer")
    },
    getCheckoutErrorResponse(data){
      this.loading = false
      alert(data.error)
      router.push("/customer-signup/"+this.phone)
    }
  }
};
</script>

<style scoped>
@import "../../../commons/css/main.css";
@import "../../../commons/css/menu.css";
@import "../../../commons/css/utils.css";

.content{
  margin-top: 60px;
}

.text-title{
  font-size: 16px;
  margin-top: 120px;
}

.vertical-center {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.business-details{
  margin-top: 70px;
}

.user-details{
  margin-top: 10px;
}

.input{
  width: 100%;
  height: 60px;
  font-size: 25px;
  font-weight: bold;
  padding: 0;
  outline: none;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: dashed 1px #636363;
}

input:hover {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  outline: none;
}

input:focus{
  border-top: 0;
  border-left: 0;
  border-right: 0;
  outline: none;
}

.button{
  width: 100%;
  height: 60px;
  background: #c82333;
  color: white;
  border: solid 1px #c82333;
  border-radius: 8px;
}

.button:hover{
  background: #9c1825;
}

.form-spacing{
  margin-top: 90px;
}

.banner{
  width: 80%;
}

.bottom{
  position: fixed;
  bottom: 0;
  width: 50%;
}

.bottom-footer{
  position: fixed;
  bottom: 0;
  width: 50%;
}

.footer-icon{
  height: 20px;
  width: 60px;
  margin-left: 10px;
}

.footer-text{
  font-size: 14px;
}


@media only screen and (max-width: 960px) {
  .text-title{
    margin-top: 60px;
    font-size: 20px;
  }

  .bottom{
    width: 100%;
    position: relative;
  }

  .bottom-footer{
    width: 100%;
  }
}

@media only screen and (max-width: 780px) {
  .banner{
    width: 60%;
  }
  .form-spacing{
    margin-top: 20px;
  }
}


@media only screen and (max-width: 640px) {
  .name{
    font-size: 14px;
  }

  .location{
    font-size: 14px;
  }

  .banner{
    width: 180px;
    margin-top: 45px;
  }

  .business-details{
    margin-top: 50px;
  }

  .content{
    margin-top: 30px;
  }

  .text-title{
    margin-top: 40px;
  }

  .form-spacing{
    margin-top: 40px;
  }

  .input{
    height: 50px;
    font-size: 20px;
  }

}

@media only screen and (max-width: 560px) {

  .form-spacing{
    margin-top: 60px;
  }

}

@media only screen and (max-width: 480px) {

  .form-spacing{
    margin-top: 30px;
  }

  .input{
    font-size: 18px;
  }

  .input{
    height: 35px;
    font-size: 14px;
  }

  .banner{
    width: 130px;
    margin-top: 40px;
  }

  .button{
    height: 36px;
    font-size: 14px;
  }

}

@media only screen and (max-width: 380px) {

  .text-title{
    font-size: 14px;
    margin-top: 40px;
  }

  .button{
    height: 40px;
    font-size: 14px;
  }

  .form-spacing{
    margin-top: 40px;
  }

  .content{
    margin-top: 10px;
  }

  .footer-text{
    font-size: 11px;
  }

  .footer-icon{
    height: 14px;
    width: 40px;
    margin-left: 5px;
  }

  .name{
    font-size: 12px;
  }

  .location{
    font-size: 12px;
  }

  .banner{
    width: 150px;
    margin-top: 50px;
  }

}

@media only screen and (max-width: 360px) {

  .banner{
    width: 130px;
    margin-top: 35px;
  }

  .business-details{
    margin-top: 40px;
  }

  .input{
    height: 35px;
  }


}

@media only screen and (max-width: 320px) {

  .text-title{
    font-size: 12px;
  }

  .input{
    font-size: 14px;
  }

  .button{
    height: 35px;
    font-size: 12px;
  }

  .name{
    font-size: 11px;
  }

  .location{
    font-size: 11px;
  }

  .banner{
    width: 110px;
    margin-top: 25px;
  }

  .form-spacing{
    margin-top: 15px;
  }

}

</style>


