<template>
  <div class="expense">
    <b-row class="header">
      <b-col cols="1" sm="2" md="3" lg="3" xl="4">
        <img @click="back()" src="../../../../assets/ic_navigate_back.png" class="y-icon-header" alt="back">
      </b-col>
      <b-col cols="10" sm="8" md="6" lg="6" xl="4" style="overflow: hidden; text-align: center;">

      </b-col>
      <b-col cols="1" sm="2" md="3" lg="3" xl="4"/>
    </b-row>

    <section class="content h-100 w-100 d-flex justify-content-center align-items-center flex-column mb-5">
      <b-col cols="6" class="mx-auto">
        <div class="mt-5  d-none d-md-block" >
<!--          <b-row style="margin-bottom: 80px;">-->
<!--            <b-col cols="12" class="text-center align-content-center">-->
<!--              <span class="font-weight-bold h3">{{ details.service_name}}</span>-->
<!--            </b-col>-->
<!--          </b-row>-->

          <b-row>
            <b-col cols="8">
              <span class="font-weight-bold h5">Location</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-row class="cj-list-item" >
                <b-col cols="10" style="padding: 20px">
                  <b-row>
                    <div class="d-flex">
                      <img src="../../../../assets/ic_location_pin.png" style="width: 20px; height: 20px; margin-right: 10px; margin-top: 3px;"/>
                      <div class="font-weight-bold">{{details.location_name}}</div>
                    </div>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row style="margin-top: 40px;">
            <b-col cols="8">
              <div class="font-weight-bold h5">What the customer did</div>
            </b-col>
            <b-col cols="4">
              <b-row>
                <b-col cols="8">
                  <span class="text" style="float:right;">{{getDateString(details.created_at)}}</span>
                </b-col>
                <b-col cols="4">
                  <span class="text" style="float:right;">{{getTime(details.created_at)}}</span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-for="(item, idx) in details.customer_services" :key="idx" >
            <b-col cols="12">
              <b-row class="cj-list-item" >
                <b-col cols="10" style="padding: 0;">
                  <div class="d-flex align-items-center">
                    <div class="item-initials-grey">
                      {{getInitial(item.service_name)}}
                    </div>
                    <div>
                      <p class="mb-0">{{item.service_name}}</p>
                    </div>
                  </div>
                </b-col>
                <b-col cols="2">
                  <div class="d-flex align-items-center" style="float: right;">
                    <p class="font-weight-bold mb-0" style="margin-top:20px;">UGX {{Number(item.price).toLocaleString()}}</p>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="1"></b-col>
                <b-col cols="10" style="margin: 10px;">
                  <b-row>
                    <b-col style="margin-bottom: 10px;">
                      <span class="font-weight-bold">Recommendation</span>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <span>{{item.recommendation}}</span>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </section>
  </div>
</template>

<script>
export default {
  name: "NewJourney",
  data(){
    return{
      months:["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      details: JSON.parse(localStorage.getItem("journey")),
      loading:false,
    }
  },
  methods:{
    back(){
      localStorage.removeItem("journey")
      window.history.back();
    },
    getInitial(name){
      return name.charAt(0).toUpperCase()+name.charAt(1).toLowerCase()
    },
    getDateString(date){
      var sections = date.split("-")
      var d = sections[2].split("T")
      var month_idx = parseInt(sections[1]) - 1
      return d[0]+" / "+this.months[month_idx]+" / "+sections[0];
    },
    getTime(date){
      var sections = date.split("T")
      var sections2 = sections[1].split(".")
      var sections3 = sections[1].split(":")
      var total = parseInt(sections2[0].replaceAll(":", ""))
      if(total > 120000){
        return sections3[0]+":"+sections3[1] + " pm"
      }else{
        return sections3[0]+":"+sections3[1] + " am"
      }
    }
  },

}
</script>

<style scoped>
@import "../../../../commons/css/main.css";

.expense{

}

.header{
  width: 100%;
  padding: 5px 10px;
  z-index: 20;
  box-shadow: 0px 5px 3px #dedede;
}

.content{
  margin-top: 20px;
}

.y-icon-header{
  padding: 5px;
  width: 45px;
  margin: 10px;
}

.y-icon-header:hover{
  padding: 6px;
  background: #f3f3f3;
  border-radius: 6px;
}

.title{
  font-size: 22px;
  margin-top: 15px;
  font-weight: bold;
  margin-left: 10px;
}

.input{
  width: 100%;
  font-size: 16px;
  justify-content: left;
  text-align: left;
}

.active{
  background: #db0000;
  color: white;
  padding: 5px;
  border: 0;
  border-radius: 3px;
}

.button{
  width: 60%;
  background: #c82333;
  font-size: 14px;
  padding: 10px;
  color: white;
  border: solid 1px #c82333;
  border-radius: 3px;
}

.button:hover{
  background: #721c24;
}

.s-button{
  width: 100%;
  background: #c82333;
  font-size: 14px;
  padding: 10px;
  color: white;
  border: solid 1px #c82333;
  border-radius: 3px;
}

.s-button:hover{
  background: #721c24;
}

.y-icon{
  width: 15px;
  margin-top: 7px;
}

.list-group{
  margin-top: 5px;
}

.cj-list-item{
  width: 100%;
  font-weight: bold;
  border-top: solid 1px #a0a0a0;
  border-bottom: solid 1px #a0a0a0;
  margin: 0;
  padding: 0;
}

.y-list-item{
  width: 100%;
  font-weight: bold;
  border-bottom: solid 1px #a0a0a0;
  margin: 0px 5px 1px 5px;
  padding: 0;
}

.y-list-item:hover{
  background: rgba(241, 241, 241, 0.55);
}

.item-initials{
  width: 60px;
  height: 60px;
  color: white;
  background: #dc3545;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  flex-shrink: 0;
  margin-right: 15px;
  font-size: 12px;
}

.item-initials-dark{
  width: 60px;
  height: 60px;
  color: white;
  background: #9c2631;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  flex-shrink: 0;
  margin-right: 15px;
  font-size: 12px;
}

.item-initials-grey{
  width: 60px;
  height: 60px;
  color: white;
  background: #a0a0a0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  flex-shrink: 0;
  margin-right: 15px;
}

.text{
  font-size: 12px;
}

.right-figures{
  margin-top: 10px;
}

.desc{
  font-size: 12px;
  color: #3D454C;
  font-weight: normal;
}

</style>