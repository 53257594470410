<template>
    <div class="expense">
      <b-row class="header">
        <b-col cols="1">
          <img @click="back()" src="../../../../assets/ic_navigate_back.png" class="y-icon" alt="back">
        </b-col>
        <b-col cols="10" sm="8" md="6" lg="6" xl="4" style="overflow: hidden; text-align: center;">

        </b-col>
        <b-col cols="1" sm="2" md="3" lg="3" xl="4"/>
      </b-row>

        <section class="h-100 w-100 d-flex flex-column ">
            <div class="center d-flex justify-content-md-center align-items-center flex-column flex-grow-1">
                <b-col cols="8" class="mx-auto mt-5 mt-md-0">
                    <b-row>
                      <div class="d-flex flex-column align-items-center mt-5 mt-md-0">
                        <img src="../../../../assets/ic_siren.png" class="y-thumb"/>
                        <p class="mt-2 d-inline-block h4 font-weight-bold">Confirm Refund</p>
                      </div>
                    </b-row>

                  <b-row>
                    <div class="d-flex flex-column align-items-center mt-5 mt-md-0">
                      <span class="text">Please confirm that you would like to refund</span>
                    </div>
                  </b-row>

                    <b-row style="margin-top: 35px;">
                      <b-col cols="3"></b-col>
                      <b-col cols="6">
                        <b-overlay :show="loading" rounded="sm">
                          <b-button class="button" @click="onRefund">
                            Confirm Delete
                          </b-button>
                        </b-overlay>
                      </b-col>
                      <b-col cols="3"></b-col>
                    </b-row>

                </b-col>
            </div>
        </section>
    </div>
</template>

<script>
    import router from "../../../../app/router/router"
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "ConfirmRefund",
      data(){
        return{
          details: JSON.parse(localStorage.getItem("request")),
          loading:false
        }
      },
        methods:{
          ...mapActions(["refundRequest"]),
            back(){
                router.push("/view-requests")
            },
          onRefund(){
            let data = {
              id: this.details.id,
              params:{
                reason: this.details.reason
              }
            }
            this.loading = true
            this.refundRequest(data)
          }
        },
      computed: {
        ...mapGetters([
          "getRequestSuccessResponse",
          "getRequestErrorResponse",
        ]),

      },
      watch:{
        getRequestSuccessResponse(){
          this.loading = false
          this.back()
        }
      }
    }
</script>

<style scoped>
    @import "../../../../commons/css/main.css";

    .header{
      width: 100%;
      padding: 5px 10px;
      z-index: 20;
      box-shadow: 0px 5px 3px #dedede;
    }

    .expense{

    }

    .y-thumb{
      width: 80px;
      margin-bottom: 10px;
    }

    .y-icon{
      padding: 5px;
      width: 45px;
      margin: 10px;
    }

    .y-icon:hover{
      padding: 6px;
      background: #f3f3f3;
      border-radius: 6px;
    }


    .center{
        margin-top: 180px;
    }

    .text{
        font-size: 14px;
    }

    .button{
      width: 100%;
      background: #c82333;
      font-size: 14px;
      padding: 20px;
      color: white;
      border: solid 1px #c82333;
      border-radius: 6px;
    }

    .button:hover{
      background: #721c24;
    }

</style>