import axios from "axios";
import configs from "../../../../app/configurations/configs";

const IP = configs.SERVER_IP;

const state = {
  response:{},
  error:{},
  r_response:{},
  r_error:{},
  rewards:[],
  redeems_history:[]
};

const getters = {
  getRewards: (state) => state.rewards,
  getRedeemsHistory: (state) => state.redeems_history,
  getRedeemRewardSuccessResponse: (state) => state.response,
  getRedeemRewardErrorResponse: (state) => state.error,
  getPointsSuccessResponse: (state) => state.response,
  getPointsErrorResponse: (state) => state.error,
  getOTPSuccessResponse: (state) => state.response,
  getOTPErrorResponse: (state) => state.error,
  getReceiptSuccessResponse: (state) => state.response,
  getReceiptErrorResponse: (state) => state.error,
};

const actions = {
  async fetchRedeemCustomer({ commit }, phone) {
    await axios
      .get(IP + "/customers/"+phone+"?business_id="+localStorage.getItem("business_id"))
      .then((response) => {
        console.log(JSON.stringify(response.data))
        commit("setSuccessResponse", response.data);
      })
      .catch((error) => {
        commit("setErrorResponse", error.response);
      });
  },

  async fetchRewards({ commit }) {
    await axios
        .get(IP + "/rewards/location/"+localStorage.getItem("location_id"))
        .then((response) => {
          console.log(JSON.stringify(response.data))
          commit("setRewardsResponse", response.data);
        })
        .catch((error) => {
          commit("setRedeemErrorResponse", error.response);
        });
  },
  async fetchRedeemsHistory({ commit }, data) {
     await axios
        .get(IP + "/redeems/customer/"+data.id+"?business_id="+localStorage.getItem("business_id"))
        .then((response) => {
            commit("setHistory", response.data);
     })
  },
  async redeemReward({ commit }, data) {
    const params = new URLSearchParams();
    params.append('business_id', localStorage.getItem("business_id"));
    params.append('location_id', localStorage.getItem("location_id"));
    params.append('customer_id', data.customer_id);
    params.append('reward_id', data.reward_id);
    params.append('transaction_type', data.transaction_type);
    params.append('points', data.points);
    await axios
        .post(IP + "/redeems", params)
        .then((response) => {
          console.log(JSON.stringify(response.data))
          commit("setRedeemResponse", response.data);
        })
        .catch((error) => {
          commit("setRedeemErrorResponse", error.response);
        });
  },

    async redeemRewardNative({ commit }, data) {
        const params = new URLSearchParams();
        params.append('business_id', localStorage.getItem("business_id"));
        params.append('location_id', localStorage.getItem("location_id"));
        params.append('customer_id', data.customer_id);
        params.append('reward_id', data.reward_id);
        params.append('receipt_no', data.receipt_no);
        // params.append('transaction_type', data.transaction_type);
        params.append('points', data.points);
        await axios
            .post(IP + "/redeems/nativeredeem", params)
            .then((response) => {
                console.log(JSON.stringify(response.data))
                commit("setRedeemResponse", response.data);
            })
            .catch((error) => {
                commit("setRedeemErrorResponse", error.response);
            });
    },

  async verifyRedeem({ commit }, data) {
    const params = new URLSearchParams();
    params.append('business_id', localStorage.getItem("business_id"));
    params.append('customer_id', data.customer_id);
    params.append('otp', data.otp);
    await axios
        .post(IP + "/redeems/verify", params)
        .then((response) => {
          console.log(JSON.stringify(response.data))
          commit("setOTPResponse", response.data);
        })
        .catch((error) => {
          commit("setOTPErrorResponse", error.response);
        });
  },

  async redeemReceipt({ commit }, data) {
    const params = new URLSearchParams();
    params.append('receipt_no', data.receipt_no);
    await axios
        .put(IP + "/redeems/update/"+data.id, params)
        .then((response) => {
          console.log(JSON.stringify(response.data))
          commit("setRcptResponse", response.data);
        })
        .catch((error) => {
          commit("setRcptErrorResponse", error.response);
        });
  },

  async givePoints({ commit }, data) {
    const params = new URLSearchParams();
    params.append('business_id', localStorage.getItem("business_id"));
    params.append('location_id', localStorage.getItem("location_id"));
    params.append('customer_id', data.customer_id);
    params.append('amount', data.amount);
    await axios
        .post(IP + "/points", params)
        .then((response) => {
          console.log(JSON.stringify(response.data))
          commit("setSuccessResponse", response.data);
        })
        .catch((error) => {
          commit("setErrorResponse", error.response);
        });
  },
};

const mutations = {
  setSuccessResponse: (state, response) => {
    state.response = response.data;
  },
  setErrorResponse: (state, response) => {
    state.error = response.data;
  },
  setRewardsResponse: (state, response) => {
    state.rewards = response.data;
  },
  setRedeemResponse: (state, response) => {
    state.response = response.data;
  },
  setRedeemErrorResponse: (state, response) => {
    state.error = response.data;
  },
  setOTPResponse: (state, response) => {
    state.response = response.data;
  },
  setOTPErrorResponse: (state, response) => {
    state.error = response.data;
  },
  setRcptResponse: (state, response) => {
    state.response = response.data;
  },
  setRcptErrorResponse: (state, response) => {
    state.error = response.data;
  },
  setHistory: (state, response) => {
    state.redeems_history = response.data;
  },


};

export default {
  state,
  getters,
  actions,
  mutations,
};
