<template>
    <div>
      <b-row>
            <b-sidebar id="sidebar" ref="mySidebar" title=""  shadow
                       aria-labelledby="sidebar-no-header-title"
                       no-header
                       backdrop-variant="dark"
                       backdrop>

                <template #footer="{ }">

                    <div class="bg-dark text-light align-items-center px-3 py-2 ft">
                        <b-row>
                            <div class="d-flex">
                                <div class="logo">
                                    <img src="../../../assets/logo.png" alt="logo" class="dp">
                                </div>
                                <div class="shop-details">
                                    <span class="font-weight-bold">{{name}}</span><br/>
                                    <span class="small">{{location}}</span>
                                </div>
                            </div>
                        </b-row>
                        <b-row>
                            <div class="logout">
                                <button class="logout-btn" @click="logout()">Logout</button>
                            </div>
                        </b-row>
                    </div>
                </template>

                <div class="px-3 py-2 side-menu bg-dark">
                    <div class="y-menu-list-group">
                        <a @click="loadPage(path)" :class="[currentPage == '/home/give-or-redeem' ? 'active':'y-menu-list-group-item']">
                            Give & Redeem Points
                        </a>

                        <a v-if="business_id == '320cd07a-40f6-4446-aabd-8c8e2b66c33c' || business_id == '279bfc07-6484-4df4-88b3-32e8b7cae35d'"
                          @click="loadPage('/home/requests')" :class="[currentPage == '/home/requests' ? 'active':'y-menu-list-group-item']">
                          Pending Requests
                        </a>

                        <a @click="loadPage('/home/apps')"  :class="[currentPage == '/home/apps' ? 'active':'y-menu-list-group-item']">
                            Apps
                        </a>
                    </div>
                </div>
            </b-sidebar>
        </b-row>

      <b-row>
        <b-col cols="12" sm="12" md="6" lg="6">
          <b-row>
            <b-col cols="2" sm="2" md="1" lg="1" style="padding-right: 0;" class="text-center">
              <img v-b-toggle.sidebar src="../../../assets/ic_grid.png" class="y-menu-icon-d"/>
            </b-col>
            <b-col cols="10" sm="10" md="11" lg="11" style="padding-left: 0;">
              <div class="heading vertical-center">
                <span class="section-title font-weight-bold">Apps</span>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="0" sm="0" md="1" lg="1"></b-col>
        <b-col cols="12" sm="12" md="10" lg="10">

          <b-row class="lineTitle">
            <b-col cols="1" sm="1"></b-col>
            <b-col cols="11" sm="11" md="12" lg="12">
              <span class="font-weight-bold title">Active apps</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="1" sm="1" ></b-col>
            <b-col cols="11" sm="11" md="12" lg="12">
              <span class="sub-title">Handpicked solutions for businesses like yours</span>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="3" md="3" lg="3"  class="top-apps" @click="goHome">
              <b-row>
                <b-col cols="3" class="mx-auto text-center align-content-center justify-content-center">
                  <img src="../../../assets/apps/ic_loyalty.png" class="app-icon">
                </b-col>
                <b-col cols="9">
                  <b-row>
                    <b-col>
                      <span class="font-weight-bold app-title">Loyalty Program</span>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="label">
                        The all-in-one point of sale for booking, payments, and more. We handle the admin while you do more of what you love.
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="9"></b-col>
                    <b-col cols="3" >
                      <b-row  style="float: right;" class="d-flex align-content-center justify-content-center" v-if="products[2].Loyalty">
                        <img src="../../../assets/ic_check.svg" class="app-indicator-icon">
                        <span class="app-indicator-label-active">Active</span>
                      </b-row>
                      <b-row  style="float: right;" class="d-flex align-content-center justify-content-center" v-else>
                        <img src="../../../assets/ic_signal.svg" class="app-indicator-icon">
                        <span class="app-indicator-label-inactive">Inactive</span>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="1"></b-col>
            <b-col cols="3" md="3" lg="3"  class="top-apps" @click="openDashboard">
              <b-row>
                <b-col cols="3"  class="mx-auto text-center align-content-center justify-content-center">
                  <img src="../../../assets/apps/ic_dashboard.png" class="app-icon">
                </b-col>
                <b-col cols="9">
                  <b-row>
                    <b-col>
                      <span class="font-weight-bold app-title">Dashboard</span>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="label">Build a customer loyalty program straight from your point of sale or your Square Online website</div><br/>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="9"></b-col>
                    <b-col cols="3" >
                      <b-row  style="float: right;" class="d-flex align-content-center justify-content-center">
                        <img src="../../../assets/ic_check.svg" class="app-indicator-icon">
                        <span class="app-indicator-label-active">Active</span>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="1"></b-col>
            <b-col cols="3" md="3" lg="3"  class="top-apps" v-if="products[0].POS" @click="openPOS">
              <b-row>
                <b-col cols="3" class="mx-auto text-center align-content-center justify-content-center">
                  <img src="../../../assets/apps/ic_pos.png" class="app-icon">
                </b-col>
                <b-col cols="9">
                  <b-row>
                    <b-col>
                      <span class="font-weight-bold app-title">Point of sale</span>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="label">
                        Point of sale solutions to fit your business. Because every business is different, Yamzit has a variety of POS options that you can use
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="8"></b-col>
                    <b-col cols="4">
                      <b-row class="d-flex align-content-center justify-content-center" v-if="products[0].POS">
                        <img src="../../../assets/ic_check.svg" class="app-indicator-icon">
                        <span class="app-indicator-label-active">Active</span>
                      </b-row>
                      <b-row class="d-flex align-content-center justify-content-center" v-else>
                        <img src="../../../assets/ic_signal.svg" class="app-indicator-icon">
                        <span class="app-indicator-label-inactive">Inactive</span>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="1"></b-col>
            <b-col cols="3" md="3" lg="3"  class="top-apps" v-if="products[2].Subscriptions">
              <b-row>
                <b-col cols="3" class="mx-auto text-center align-content-center justify-content-center">
                  <img src="../../../assets/apps/ic_subs.png" class="app-icon">
                </b-col>
                <b-col cols="9">
                  <b-row>
                    <b-col>
                      <span class="font-weight-bold app-title">Subscriptions</span>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="label">
                        The all-in-one point of sale for booking, payments, and more. We handle the admin while you do more of what you love.
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="8"></b-col>
                    <b-col cols="4">
                      <b-row class="d-flex align-content-center justify-content-center" v-if="products[2].Subscriptions">
                        <img src="../../../assets/ic_check.svg" class="app-indicator-icon">
                        <span class="app-indicator-label-active">Active</span>
                      </b-row>
                      <b-row class="d-flex align-content-center justify-content-center" v-else>
                        <img src="../../../assets/ic_signal.svg" class="app-indicator-icon">
                        <span class="app-indicator-label-inactive">Inactive</span>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="1"></b-col>
            <b-col cols="3" md="3" lg="3"  class="top-apps" v-if="products[3].Appointments">
              <b-row>
                <b-col cols="3" class="mx-auto text-center align-content-center justify-content-center">
                  <img src="../../../assets/apps/ic_appts.png" class="app-icon">
                </b-col>
                <b-col cols="9">
                  <b-row>
                    <b-col>
                      <span class="font-weight-bold app-title">Appointments</span>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="label">
                        The all-in-one point of sale for booking, payments, and more. We handle the admin while you do more of what you love.
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="8"></b-col>
                    <b-col cols="4">
                      <b-row class="d-flex align-content-center justify-content-center" v-if="products[3].Appointments">
                        <img src="../../../assets/ic_check.svg" class="app-indicator-icon">
                        <span class="app-indicator-label-active">Active</span>
                      </b-row>
                      <b-row class="d-flex align-content-center justify-content-center" v-else>
                        <img src="../../../assets/ic_signal.svg" class="app-indicator-icon">
                        <span class="app-indicator-label-inactive">Inactive</span>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

        </b-col>
        <b-col cols="0" sm="0" md="1" lg="1"></b-col>
      </b-row>

      <b-row class="lower-apps">
        <b-col cols="12">
          <b-row>
            <b-col cols="1" sm="1" md="1" lg="1"></b-col>
            <b-col cols="10" sm="10" md="10" lg="10">
              <b-row class="line">
                <b-col cols="12">
                  <span class="font-weight-bold title">Most popular apps</span>
                </b-col>
                <b-col cols="12">
                  <span class="sub-title">Explore the apps that a wide range of merchants rely on.</span>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="3" md="3" lg="3" class="apps">
                  <b-row>
                    <b-col cols="3" class="mx-auto text-center align-content-center justify-content-center">
                      <img src="../../../assets/apps/ic_loyalty.png" class="app-icon">
                    </b-col>
                    <b-col cols="9">
                      <b-row>
                        <b-col>
                          <span class="font-weight-bold app-title">Loyalty Program</span>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <p class="label">
                            Get your customers to spend more at your business on each visit
                          </p>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="1"></b-col>
                <b-col cols="3" md="3" lg="3" class="apps">
                  <b-row>
                    <b-col cols="3" class="mx-auto text-center align-content-center justify-content-center">
                      <img src="../../../assets/apps/ic_subs.png" class="app-icon">
                    </b-col>
                    <b-col cols="9">
                      <b-row>
                        <b-col>
                          <span class="font-weight-bold app-title">Subscriptions</span>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <p class="label">
                            Track all monthly membership subscriptions at your business
                          </p>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="1"></b-col>
                <b-col cols="3" md="3" lg="3" class="apps">
                  <b-row>
                    <b-col cols="3" class="mx-auto text-center align-content-center justify-content-center">
                      <img src="../../../assets/apps/ic_payment.png" class="app-icon">
                    </b-col>
                    <b-col cols="9">
                      <b-row>
                        <b-col>
                          <span class="font-weight-bold app-title">Payments</span>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <p class="label">
                            Collect digital payments in real time using mobile money & Cards
                          </p>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="1"></b-col>
              </b-row>

              <b-row class="line">
                <b-col cols="3" md="3" lg="3" class="apps">
                  <b-row>
                    <b-col cols="3" class="mx-auto text-center align-content-center justify-content-center">
                      <img src="../../../assets/apps/ic_store_mgr.png" class="app-icon">
                    </b-col>
                    <b-col cols="9">
                      <b-row>
                        <b-col>
                          <span class="font-weight-bold app-title">Store Manager</span>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <p class="label">Coming soon ...</p>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="1"></b-col>
                <b-col cols="3" md="3" lg="3" class="apps">
                  <b-row>
                    <b-col cols="3" class="mx-auto text-center align-content-center justify-content-center">
                      <img src="../../../assets/apps/ic_marketplace.png" class="app-icon">
                    </b-col>
                    <b-col cols="9">
                      <b-row>
                        <b-col>
                          <span class="font-weight-bold app-title">Martketplace</span>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <p class="label">Coming soon ...</p>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="1"></b-col>
                <b-col cols="3" md="3" lg="3" class="apps">
                  <b-row>
                    <b-col cols="3" class="mx-auto text-center align-content-center justify-content-center">
                      <img src="../../../assets/apps/ic_marketing.png" class="app-icon">
                    </b-col>
                    <b-col cols="9">
                      <b-row>
                        <b-col>
                          <span class="font-weight-bold app-title">Marketing</span>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <p class="label">Coming soon ...</p>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="1"></b-col>
              </b-row>

            </b-col>
            <b-col cols="1" sm="1" md="1" lg="1"></b-col>
          </b-row>
        </b-col>
      </b-row>

    </div>
</template>

<script>
    import router from "../../../app/router/router";

    export default {
        name: "Apps",
        data(){
            return{
                activeClass:'active',
                currentPage: this.$route.fullPath,
                title: this.titleParser(this.$route.fullPath),
                name: JSON.parse(localStorage.getItem("business")).name,
                business_id: localStorage.getItem("business_id"),
                location: localStorage.getItem("location_name"),
                path: "/home/give-or-redeem",
                products:JSON.parse(localStorage.getItem("business")).active_products
            }
        },
        mounted() {
            if(localStorage.getItem("token") == null){
                router.push("/")
            }
        },
        methods:{
            closeSideMenu(){
                this.$refs.mySidebar.hide();
            },

            loadPage(route){
                router.push(route)
            },
            titleParser(route){
                var name = route.split("/")[2]
                return this.capitalizeFirstLetter(name)
            },
            capitalizeFirstLetter(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            },
            logout(){
                localStorage.clear()
                router.push("/")
            },
            goHome(){
              router.push("/home/give-or-redeem")
            },
            openPOS(){
               window.open("https://pos.yamzit.com").focus()
            },
            openDashboard(){
              window.open("https://dashboard.yamzit.com").focus()
            }

        },
    }
</script>

<style scoped>
    @import "../../../commons/css/main.css";
    @import "../../../commons/css/menu.css";
    @import "../../../commons/css/utils.css";

    .vertical-center {
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    .title{
      font-size: 22px;
    }

    .sub-title{
      font-size: 14px;
    }

    .line{
      margin-top: 50px;
      margin-bottom: 20px;
    }

    .lineTitle{
      margin-top: 50px;
    }

    .line-spacing{
      margin-top: 30px;
      margin-bottom: 20px;
    }

    .default-app{
      border-radius: 6px;
      background: #f1f1f1;
      padding: 10px;
      margin-top: 30px;
    }

    .default-app:hover{
      background: #dedede;
    }

    .top-apps{
      border-radius: 6px;
      background: #f1f1f1;
      padding: 10px;
    }

    .top-apps:hover{
      background: #dedede;
    }

    .apps{
      border-radius: 6px;
      background: #f1f1f1;
      padding: 10px;
    }

    .apps:hover{
      background: #dedede;
    }

    .label{
      font-size: 12px;
    }

    .app-icon{
      width: 65px;
      height: 65px;
      margin-top: 5px;
    }

    .lower-apps{
      background: #f1f1f1;
      margin-top: 100px;
      padding-bottom: 50px;
    }

    .app-indicator-icon{
      width: 10px;
      height: 10px;
      margin-top: auto;
      margin-bottom: auto;
    }

    .app-indicator-label-active{
      font-size: 9px;
      font-weight: bold;
      color: #1e7e34;
      margin-left: 3px;
    }

    .app-indicator-label-inactive{
      font-size: 9px;
      font-weight: bold;
      color: #bd2130;
      margin-left: 3px;
    }

    @media only screen and (max-width: 1281px){
      .label{
        font-size: 11px;
      }
    }

    @media only screen and (max-width: 380px) {

      .title{
        font-size: 16px;
      }

      .lineTitle{
        margin-top: 20px;
      }

      .sub-title{
        font-size: 11px;
      }

      .top-apps{
        padding: 3px;
        margin-top: 10px;
        margin-left: 20px;
        margin-right: 20px;
        width: 90%;
      }

      .apps{
        padding: 3px;
        margin-top: 10px;
        width: 100%;
      }

      .app-icon{
        width: 40px;
        height: 40px;
        margin-top: 3px;
      }

      .line{
        margin-top: 20px;
        margin-bottom: 10px;
      }

      .app-title{
        font-size: 11px;
      }

      .label{
        font-size: 10px;
      }

      .lower-apps{
        margin-top: 50px;
        padding-bottom: 25px;
      }

    }

    @media only screen and (max-width: 320px) {

    }


</style>
