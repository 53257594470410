<template>
  <div class="expense">

    <b-row>
      <b-modal id="categories"
               size="lg" centered hide-footer
               header-bg-variant="light"
               body-bg-variant="light">

        <b-col cols="12">

          <b-row>
            <b-col cols="12">
              <div class="font-weight-bold h5 text-center align-content-center">{{ popup_title }}</div>
            </b-col>
          </b-row>

          <div class="list-group list-group-flush" v-if="showCategories">
            <b-overlay :show="loadingL" sm="rounded">
              <b-row class="y-list-item" v-for="(category, idx) in getCategories" :key="idx" @click="selectCategory(category.id)">
                <b-col cols="11" style="padding: 0;">
                  <div class="d-flex align-items-center">
                    <div :class="[(idx % 2) ? 'item-initials-dark':'item-initials']">
                      {{getInitial(category.name)}}
                    </div>
                    <div>
                      <p class="mb-0 text">{{category.name}}</p>
                    </div>
                  </div>
                </b-col>
                <b-col cols="1">
                  <div class="right-figures">
                    <img src="../../../../assets/ic_next.png" class="y-icon" alt="back">
                  </div>
                </b-col>
              </b-row>
            </b-overlay>
          </div>

          <div class="list-group list-group-flush" v-if="showCategoryItems">
            <b-overlay :show="loadingL" sm="rounded">
              <b-row class="y-list-item" v-for="(citem, idx) in getCategoryItems" :key="idx" @click="selectItem(citem)">
                <b-col cols="12" style="padding: 0;">
                  <b-row>
                    <b-col cols="1">
                      <div class="item-initials-grey">
                        {{getInitial(citem.name)}}
                      </div>
                    </b-col>
                    <b-col cols="11" style="margin-top: 15px;">
                      <b-row style="padding: 0; margin: 0">
                        <b-col cols="6">
                          <span class="font-weight-bold text">{{citem.name}}</span>
                        </b-col>
                        <b-col cols="6">
                          <div style="float: right;">
                            <span class="font-weight-bold mb-0" style="font-size: 11px;">
                              UGX {{Number(citem.price).toLocaleString()}}
                            </span>
                          </div>
                        </b-col>
                      </b-row>
                      <b-row style="padding: 0; margin: 0;">
                        <b-col>
                          <span class="desc">{{citem.description}}</span>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-overlay>
          </div>
        </b-col>

      </b-modal>
    </b-row>


    <b-row class="header">
      <b-col cols="1">
        <a class="navbar-brand ml-3">
          <img @click="back()" src="../../../../assets/ic_navigate_back.png" class="y-icon-header">
        </a>
      </b-col>

      <b-col cols="9" style="margin: auto;">
        <div class="flex-grow-1 d-md-flex align-items-center justify-content-center d-none">

        </div>
      </b-col>

      <b-col cols="2" style="margin: auto;">
        <button @click="save()" class="button">Save</button>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="3"></b-col>
      <b-col cols="6">
        <div class="mt-5  d-none d-md-block">
            <span class="font-weight-bold h5">Details</span>
            <table class="table w-100 font-weight-bold table-bordered">
              <tbody>
              <tr>
                <th class="bg-light">Category</th>
                <td class="font-weight-normal">
                  <b-form-select v-model="form.location_id" class="select">
                    <b-select-option value="null">Locations</b-select-option>
                    <b-select-option v-for="(location, idx) in getLocations" :key="idx" :value="location.id">
                      {{location.location_name}} ({{location.address}})
                    </b-select-option>
                  </b-form-select>
                </td>
              </tr>
              <tr>
                <th class="bg-light">Receipt Number</th>
                <td class="font-weight-normal">
                  <b-form-input
                      class="input"
                      v-model="form.receipt_no"
                      type="text"
                      placeholder="Receipt Number"/>
                </td>
              </tr>
              </tbody>
            </table>
            <b-row>
              <b-col cols="9"> </b-col>
              <b-col cols="3">
                <button  v-b-modal.categories class="s-button">Add Service
                <b-spinner v-if="loading" small class="ml-1"></b-spinner></button>
              </b-col>
            </b-row>

            <table class="table w-100 mt-5 font-weight-bold">
              <tbody>
              <tr class="border">
                <th class="bg-light" style="border-right: solid 1px #dedede">Search for Service</th>
                <td class="font-weight-normal">
                  <b-form-input
                      class="input"
                      v-model="searchText"
                      type="text"
                      placeholder="Type service name here"/>
                </td>
                <td>
                  <b-spinner v-if="loadingSearch" small class="mx-2"></b-spinner>
                </td>
              </tr>
              </tbody>
            </table>
        </div>

        <div class="mt-5 d-none d-md-block">
          <b-row class="y-list-item" v-for="(category, idx) in getServiceResults" :key="idx" @click="selectItem(category)">
            <b-col cols="11" style="padding: 0;">
              <div class="d-flex align-items-center">
                <div :class="[(idx % 2) ? 'item-initials-dark':'item-initials']">
                  {{getInitial(category.name)}}
                </div>
                <div>
                  <p class="mb-0 text">{{category.name}}</p>
                </div>
              </div>
            </b-col>
            <b-col cols="1">
              <div class="right-figures">
                <img src="../../../../assets/ic_next.png" class="y-icon" alt="back">
              </div>
            </b-col>
          </b-row>

        </div>

        <div class="mt-5 d-none d-md-block" v-if="form.items.length > 0">
          <span class="font-weight-bold h5">Customers Choice</span>
          <b-row v-for="(item, idx) in form.items" :key="idx" >
            <b-col cols="12">
              <b-row class="cj-list-item" >
                <b-col cols="9" style="padding: 0;">
                  <div class="d-flex align-items-center">
                    <div class="item-initials-grey">
                      {{getInitial(item.item_name)}}
                    </div>
                    <div style="margin-top: 10px;">
                      <p class="mb-0 text">{{item.item_name}}</p>
                      <p class="desc">{{item.item_description}}</p>
                    </div>
                  </div>
                </b-col>
                <b-col cols="2">
                  <div class="d-flex align-items-center" style="float: right;">
                    <p class="font-weight-bold mb-0" style="margin-top:20px;">UGX {{Number(item.item_price).toLocaleString()}}</p>
                  </div>
                </b-col>
                <b-col cols="1">
                  <div class="right-figures">
                    <img src="../../../../assets/ic_cancel.png" class="y-icon" alt="back" @click="form.items.splice(idx, 1)">
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="1"></b-col>
                <b-col cols="10" style="margin: 10px;">
                  <b-row>
                    <b-col style="margin-bottom: 10px;">
                      <span class="font-weight-bold">Recommendation</span>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-textarea
                          v-model="form.items[idx].recommendation"
                          class="border-0 form-control"
                          cols="30" rows="2"
                          placeholder="Type here"></b-textarea>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col cols="3">

      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import router from "../../../../app/router/router";

export default {
  name: "NewJourney",
  data(){
    return{
      form:{
        service_name:null,
        location_id:localStorage.getItem("location_id"),
        customer_id: JSON.parse(localStorage.getItem("customer")).id,
        receipt_no:null,
        items:[]
      },
      searchText:null,
      showCategories:true,
      showCategoryItems:false,
      showLibrary:false,
      loading:false,
      loadingL:true,
      loadingSearch:false,
      popup_title: "Library",
    }
  },
  mounted() {
    this.fetchLocations()
    this.fetchCategories()
  },
  methods:{
    ...mapActions(["fetchLocations", "fetchCategories", "searchServices", "searchServicesNull", "fetchCategoryItems", "addJourney"]),
    back(){
      window.history.back();
    },
    save(){
      this.loading = true
      this.addJourney(this.form)
    },
    selectCategory(id){
      this.fetchCategoryItems(id);
      this.loadingL = true
    },
    selectItem(item){
      let data = {
        item_name:item.name,
        item_price: item.price,
        item_description: item.description,
        recommendation:""
      }
      this.form.items.push(data)
      this.showCategories = true
      this.showCategoryItems = false
      this.$bvModal.hide("categories")
      this.searchServicesNull()
    },
    getInitial(name){
      return name.charAt(0).toUpperCase()+name.charAt(1).toLowerCase()
    },
  },
  computed:{
    ...mapGetters(["getLocations", "getCategories", "getCategoryItems", "getJourneySuccessResponse",
      "getJourneyErrorResponse", "getServiceResults"]),
  },
  watch:{
    searchText:{
      handler(text){
        this.loadingSearch = true
        this.searchServices(text)
      }
    },
    getServiceResults(){
      this.loadingSearch = false
    },
    getCategories(){
      this.popup_title = "Library"
      this.loadingL = false
    },
    getCategoryItems(){
      this.showCategories = false
      this.showCategoryItems = true
      this.popup_title = "Membership Packages"
      this.loadingL = false
    },
    getJourneySuccessResponse(){
      this.loading = false
      router.push("/home/give-or-redeem")
    },
    getJourneyErrorResponse(data){
      this.loading = false
      alert(JSON.stringify(data))
    }
  }
}
</script>

<style scoped>
@import "../../../../commons/css/main.css";

.expense{

}

.header{
  /*margin: 0;*/
  /*width: 100%;*/
  /*box-shadow: 0px 5px 3px #dedede;*/
  padding: 0;
  margin: 0;
  width: 100%;
  height: 85px;
  box-shadow: 0px 5px 3px #dedede;
}

.content{
  margin-top: 20px;
}

.y-icon-header{
  padding: 5px;
  width: 38px;
  height: 38px;
  margin: 16px 10px 10px 10px
}

.y-icon-header:hover{
  padding: 6px;
  background: #f3f3f3;
  border-radius: 6px;
}

.title{
  font-size: 22px;
  margin-top: 15px;
  font-weight: bold;
  margin-left: 10px;
}

.input{
  width: 100%;
  font-size: 16px;
  justify-content: left;
  text-align: left;
}

.active{
  background: #db0000;
  color: white;
  padding: 5px;
  border: 0;
  border-radius: 3px;
}

.button{
  width: 60%;
  background: #c82333;
  font-size: 14px;
  padding: 10px;
  color: white;
  border: solid 1px #c82333;
  border-radius: 3px;
}

.button:hover{
  background: #721c24;
}

.s-button{
  width: 100%;
  background: #c82333;
  font-size: 14px;
  padding: 10px;
  color: white;
  border: solid 1px #c82333;
  border-radius: 3px;
}

.s-button:hover{
  background: #721c24;
}

.y-icon{
  width: 15px;
  margin-top: 7px;
}

.y-icon:hover{
  padding: 2px;
  border-radius: 3px;
  background: #f1f1f1;
}

.list-group{
  margin-top: 5px;
}

.cj-list-item{
  width: 100%;
  font-weight: bold;
  border-top: solid 1px #a0a0a0;
  border-bottom: solid 1px #a0a0a0;
  margin: 0px 5px 1px 5px;
  padding: 0;
}

.y-list-item{
  width: 100%;
  font-weight: bold;
  border-bottom: solid 1px #a0a0a0;
  margin: 0px 5px 1px 5px;
  padding: 0;
}

.y-list-item:hover{
  background: rgba(241, 241, 241, 0.55);
}

.item-initials{
  width: 60px;
  height: 60px;
  color: white;
  background: #dc3545;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  flex-shrink: 0;
  margin-right: 15px;
  font-size: 12px;
}

.item-initials-dark{
  width: 60px;
  height: 60px;
  color: white;
  background: #9c2631;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  flex-shrink: 0;
  margin-right: 15px;
  font-size: 12px;
}

.item-initials-grey{
  width: 60px;
  height: 60px;
  color: white;
  background: #a0a0a0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  flex-shrink: 0;
  margin-right: 15px;
  font-size: 12px;
}

.text{
  font-size: 12px;
}

.right-figures{
  margin-top: 10px;
}

.desc{
  font-size: 12px;
  color: #3D454C;
  font-weight: normal;
}

</style>