<template>
    <div>
      <RewardsHeader title="" style="z-index: 20"/>

      <b-row>
        <b-col cols="4"></b-col>
        <b-col cols="4">
          <b-row>
            <b-col class="text-center justify-content-center">
              <h3 class="h3 mx-auto bold title">VIEW HISTORY</h3>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="4">

        </b-col>
      </b-row>
      <!--content-->
      <b-row style="margin-top: 40px;">
        <b-col cols="3"></b-col>
        <b-col cols="6">
          <b-overlay :show="loading" rounded="sm">
            <b-row>
              <b-col cols="12">
                <b-row class="y-list-header">
                  <b-col cols="6">
                            <span class="text font-weight-bold">
                               Date
                            </span>
                  </b-col>
                  <b-col cols="4">
                            <span class="text font-weight-bold">
                              Amount
                            </span>
                  </b-col>
                  <b-col cols="2">
                            <span class="text font-weight-bold" style="float: right;">
                              Points
                            </span>
                  </b-col>
                </b-row>
                <b-row class="y-list-item"
                       v-for="(txn, idx) in getPointsHistory"
                       :key="idx">
                  <b-col cols="6">
                            <span class="text">
                               {{getDateString(txn.created_at)}}
                            </span>
                  </b-col>
                  <b-col cols="4">
                            <span class="text">
                              {{Number(txn.amount).toLocaleString()}}
                            </span>
                  </b-col>
                  <b-col cols="2">
                            <span class="text" style="float: right;">
                              {{Number(txn.points).toLocaleString()}}
                            </span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-overlay>
        </b-col>
        <b-col cols="3"></b-col>
      </b-row>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import RewardsHeader from "../components/RewardsHeader";
    import moment from "moment"

    export default {
        name: "PointsHistory",
        components: {RewardsHeader},
        data(){
            return{
                customer:JSON.parse(localStorage.getItem("customer")),
                loading:false
            }
        },
        mounted() {
          this.loading = true
          let data ={
            id: this.customer.id
          }
          this.fetchPointsHistory(data)
        },
        methods:{
            ...mapActions(["fetchPointsHistory"]),
          getDateString(date){
            return moment(date).format("DD/MM/YYYY")
          },

        },
        computed: {
            ...mapGetters([
                "getPointsHistory",
            ]),
        },
        watch:{
          getPointsHistory(){
            this.loading = false
          }
        }
    }
</script>

<style scoped>
    @import "../../../../commons/css/main.css";

    .bold{
        font-weight: bold;
    }

    .title{
        margin-top: 80px;
    }

    .text{
        font-size: 14px;
    }

    .y-list-header{
      padding: 10px;
      background: #bd2130;
      color: white;
    }

    .y-list-item{
        padding: 10px;
        border-bottom: solid 1px #a7a7a7;
    }

    .y-list-item:hover{
        background: #f1f1f1;
    }
</style>