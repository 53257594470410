<template>
    <div>
        <b-row class="header">
          <b-col cols="1">
            <img @click="back()" src="../../../../assets/ic_navigate_back.png" class="y-icon" alt="back">
          </b-col>
          <b-col cols="10" sm="8" md="6" lg="6" xl="4" style="overflow: hidden; text-align: center;">

          </b-col>
          <b-col cols="1" sm="2" md="3" lg="3" xl="4"/>
        </b-row>
        <!--content-->
        <b-row class="margin-top">
            <b-col cols="4"></b-col>
            <b-col cols="4">
              <b-row class="form-spacing">
                <b-col cols="12">
                  <b-row class="align-content-center fill-width-parent">
                    <b-col>
                      <input type="text"
                             class="input"
                             v-model="reason"
                             placeholder="Write your reason here"/>
                    </b-col>
                  </b-row>

                  <b-row class="mt-5" style="width: 100%">
                    <b-col cols="12" class="text-center">
                      <b-overlay :show="loading" rounded="sm">
                        <button class="button"
                                type="button"
                                @click="next">Next</button>
                      </b-overlay>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="4"></b-col>
        </b-row>

        <b-row class="bottom">
          <b-col  cols="12" class="text-center justify-content-center align-content-center py-2">
            <b-row>
              <b-col>
                <span class="footer-text">Powered By</span>
                <img src="../../../../assets/logo_small_size.png" class="footer-icon"/>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
    </div>
</template>

<script>
    import router from "../../../../app/router/router";

    export default {
        name: "GiveRefundReason",
        data(){
            return{
              customer:JSON.parse(localStorage.getItem("customer")),
              details: JSON.parse(localStorage.getItem("request")),
              reason:null,
              loading:false
            }
        },
        methods:{
            next(){
              if(this.reason != null && this.reason.length > 4){
                this.details.reason = this.reason
                localStorage.setItem("request", JSON.stringify(this.details))
                router.push("/confirm-refund")
              }else{
                alert("Enter a reason")
              }
            },
            back(){
              window.history.back();
            }
        },

    }
</script>

<style scoped>
    @import "../../../../commons/css/main.css";

    .header{
      width: 100%;
      padding: 5px 10px;
      z-index: 20;
      box-shadow: 0px 5px 3px #dedede;
    }

    .y-icon{
      padding: 5px;
      width: 45px;
      margin: 10px;
    }

    .y-icon:hover{
      padding: 6px;
      background: #f3f3f3;
      border-radius: 6px;
    }

    .banner{
      width: 80%;
    }

    .bold{
        font-weight: bold;
    }

    .title{
        margin-top: 10px;
    }

    .input{
      width: 100%;
      outline: none;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom: solid 1px #636363;
    }

    input:hover {
      border-top: 0;
      border-left: 0;
      border-right: 0;
      outline: none;
    }

    input:focus{
      border-top: 0;
      border-left: 0;
      border-right: 0;
      outline: none;
    }

    .button{
      width: 100%;
      height: 60px;
      background: #c82333;
      color: white;
      border: solid 1px #c82333;
      border-radius: 8px;
    }

    .button:hover{
      background: #9c1825;
    }

    .margin-top{
      margin-top: 160px;
    }

    .outline-button{
      background: #fff;
      color: #c82333;
      padding: 5px;
      font-weight: bold;
      border: solid 2px #c82333;
      border-radius: 6px;
    }

    .outline-button:hover{
      background: #c82333;
      color: #fff;
    }

    .form-spacing{
      margin-top: 20px;
    }

    .bottom{
      position: fixed;
      bottom: 0;
      width: 100%;
    }

    .footer-icon{
      height: 20px;
      width: 60px;
      margin-left: 10px;
    }

    .footer-text{
      font-size: 14px;
    }


</style>