<template>
    <div>
        <RewardsHeader title=""/>

        <b-row style="margin-top: 10px;">
            <b-col cols="4"></b-col>
            <b-col cols="4" class="text-center">
                <b-row>
                  <b-col class="text-center justify-content-center">
                    <h3 class="h3 mx-auto bold form-spacing">OTP</h3>
                  </b-col>
                </b-row>
            </b-col>
            <b-col cols="4"></b-col>
        </b-row>
        <!--content-->
        <b-row>
            <b-col cols="1" sm="1" md="2" lg="2"></b-col>
            <b-col cols="10" sm="10" md="8" lg="8">
              <b-row>
                <b-col cols="12" class="text-center justify-content-center">
                  <p class="h5 title">Enter OTP sent to customer's phone number</p>
                </b-col>
              </b-row>
              <b-row class="form-spacing">
                <b-col cols="1" sm="1" md="3" lg="3"></b-col>
                <b-col cols="10" md="6" lg="6">
                  <b-row class="align-content-center">
                    <b-col>
                      <input type="text"
                             class="input form-control"
                             v-model="otp"
                             placeholder="Code"
                             aria-describedby="basic-addon2"/>
                    </b-col>
                  </b-row>

                  <b-row class="mt-5" style="width: 100%">
                    <b-col cols="12" class="text-center">
                      <b-overlay :show="loading" rounded="sm">
                        <button class="button"
                                type="button"
                                @click="onSubmit()">Next</button>
                      </b-overlay>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="1" sm="1" md="3" lg="3"></b-col>
              </b-row>
            </b-col>
            <b-col cols="1" sm="1" md="2" lg="2"></b-col>
        </b-row>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import RewardsHeader from "../components/RewardsHeader";
    import router from "../../../../app/router/router";

    export default {
        name: "RedeemOTP",
        components: {RewardsHeader},
        data(){
            return{
              customer:JSON.parse(localStorage.getItem("customer")),
              otp:null,
              loading:false
            }
        },
        methods:{
            ...mapActions(["verifyRedeem"]),
            onSubmit(){
              if(localStorage.getItem("business_id") === "1d4524b4-c484-4f59-b571-5933dfdcc2c8" || localStorage.getItem("business_id") === "d6735c0e-9b41-4333-b807-47cfa8000166"){
                this.loading = true
                let data = {
                  customer_id:this.customer.id,
                  otp:this.otp
                }
                this.verifyRedeem(data)
              }
            },
        },
        computed: {
            ...mapGetters([
                "getOTPSuccessResponse",
                "getOTPErrorResponse",
            ]),
        },
        watch:{
            getOTPSuccessResponse(data){
              this.loading = false
              localStorage.setItem("otp-response", JSON.stringify(data))
              router.push("/complete/redeem/receipt-no")
            },
            getOTPErrorResponse(data){
              this.loading = false
              alert(data.message)
            }
        }
    }
</script>

<style scoped>
    @import "../../../../commons/css/main.css";

    .bold{
        font-weight: bold;
    }

    .title{
        margin-top: 80px;
    }

    .input{
      width: 100%;
      border-radius: 0;
      font-size: 30px;
      font-weight: bold;
      text-align: center;
      outline: none;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom: solid 1px #636363;
    }

    input:hover {
      border-top: 0;
      border-left: 0;
      border-right: 0;
      outline: none;
    }

    input:focus{
      border-top: 0;
      border-left: 0;
      border-right: 0;
      outline: none;
    }

    .button{
      width: 100%;
      height: 60px;
      background: #c82333;
      color: white;
      border: solid 1px #c82333;
      border-radius: 8px;
    }

    .button:hover{
      background: #9c1825;
    }

    .outline-button{
      background: #fff;
      color: #c82333;
      padding: 5px;
      font-weight: bold;
      border: solid 2px #c82333;
      border-radius: 6px;
    }

    .outline-button:hover{
      background: #c82333;
      color: #fff;
    }

    .form-spacing{
      margin-top: 60px;
    }

    @media only screen and (max-width: 640px){
      .input{
        height: 50px;
        font-size: 22px;
      }
    }

    @media only screen and (max-width: 560px){
      .form-spacing{
        margin-top: 40px;
      }

      .button{
        font-size: 14px;
        height: 50px;
      }

    }

    @media only screen and (max-width: 480px){
      .title{
        font-size: 14px;
      }
    }

    @media only screen and (max-width: 380px) {

      .input{
        height: 40px;
        font-size: 16px;
      }

    }

    @media only screen and (max-width: 320px) {
      .button{
        font-size: 12px;
        height: 35px;
      }
    }

</style>