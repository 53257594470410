<template>
    <div>
        <RequestsHeader title=""/>

        <!--content-->
        <b-row style="margin-top: 10px;">
            <b-col cols="2"></b-col>
            <b-col cols="8">
              <b-row>
                <b-col cols="12" class="text-center justify-content-center">
                  <p class="h5 title font-weight-bold">Enter Receipt No</p>
                </b-col>
              </b-row>
              <b-row class="form-spacing">
                <b-col cols="3"></b-col>
                <b-col cols="6">
                  <b-row class="align-content-center fill-width-parent">
                    <b-col>
                      <input type="text"
                             class="input form-control"
                             v-model="number"
                             placeholder="Receipt Number"/>
                    </b-col>
                  </b-row>

                  <b-row class="mt-5" style="width: 100%">
                    <b-col cols="12" class="text-center">
                      <b-overlay :show="loading" rounded="sm">
                        <button class="button"
                                type="button"
                                @click="onSubmit()">Search</button>
                      </b-overlay>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="3"></b-col>
              </b-row>
            </b-col>
            <b-col cols="2"></b-col>
        </b-row>

      <b-row class="bottom">
        <b-col cols="12" class="text-center justify-content-center align-content-center py-2">
          <span class="footer-text">Powered By</span>
          <img src="../../../../assets/logo_small_size.png" class="footer-icon"/>
        </b-col>
      </b-row>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import RequestsHeader from "../components/RequestsHeader";
    import router from "../../../../app/router/router";

    export default {
        name: "SearchReceipt",
        components: {RequestsHeader},
        data(){
            return{
              customer:JSON.parse(localStorage.getItem("customer")),
              loading:false,
              number:null
            }
        },
        methods:{
            ...mapActions(["fetchRewards", "givePoints"]),
            onSubmit(){
              this.loading = false
            },
            goToHistory(){
              router.push("/points-history")
            }
        },
        computed: {
            ...mapGetters([
                "getRewards",
            ]),
        },
        watch:{
            getRedeemSuccessResponse(){
                window.history.back();
            },
            getRedeemErrorResponse(data){
                alert(data.message)
            }
        }
    }
</script>

<style scoped>
    @import "../../../../commons/css/main.css";

    .bold{
        font-weight: bold;
    }

    .title{
        margin-top: 80px;
    }

    .input{
      width: 100%;
      height: 60px;
      font-size: 18px;
      border-bottom: solid 1px #dedede;
    }

    .button{
      width: 100%;
      height: 60px;
      background: #c82333;
      color: white;
      border: solid 1px #c82333;
      border-radius: 8px;
    }

    .button:hover{
      background: #9c1825;
    }

    .outline-button{
      background: #fff;
      color: #c82333;
      padding: 5px;
      font-weight: bold;
      border: solid 2px #c82333;
      border-radius: 6px;
    }

    .outline-button:hover{
      background: #c82333;
      color: #fff;
    }

    .form-spacing{
      margin-top: 60px;
    }

    .bottom{
      position: fixed;
      bottom: 0;
      width: 100%;
    }

    .footer-icon{
      height: 20px;
      width: 60px;
      margin-left: 10px;
    }

    .footer-text{
      font-size: 14px;
    }
</style>