<template>
    <div>
        <RewardsHeader title=""/>

        <b-row style="margin-top: 10px;">
            <b-col cols="2" sm="2" md="5" lg="5"></b-col>
            <b-col cols="5" sm="4" md="2" lg="2">
              <b-row>
                <b-col class="text-center justify-content-center">
                  <h3 class="h3 mx-auto bold title">CUSTOMER JOURNEY</h3>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-center justify-content-center">
                  <button class="outline-button" @click="goToNewJourney">New Journey</button>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="4" sm="5" md="3" lg="3">
                <img src="../../../../assets/services/service_customer_journey.svg" class="banner"/>
            </b-col>
            <b-col cols="1" sm="1" md="2" lg="2"></b-col>
        </b-row>
        <!--content-->
        <b-row style="margin-top: 40px;">
            <b-col cols="1" sm="1"  md="2" lg="2"></b-col>
            <b-col cols="10" sm="10" md="8" lg="8">
              <b-overlay :show="loading" rounded="sm">
                <b-row>
                  <b-col cols="12">
                    <div  class="flex-grow-1">
                      <div class="list-group"
                           v-for="(journey, idx) in getJourneys"
                           :key="idx" >
                        <b-row style="margin-bottom: 10px; margin-top: 20px;">
                          <b-col cols="8" sm="8" md="4" lg="4">
                            <span class="section-date">
                              {{journey.date.toUpperCase()}}
                            </span>
                          </b-col>
                          <b-col cols="4" sm="4" md="8" lg="8"> </b-col>
                        </b-row>

                        <b-row class="y-list-group-item"
                               v-for="(jny, idx) in journey.journeys"
                               :key="idx"
                               @click="onSelect(jny)">
                          <b-col cols="4" sm="5" md="7" lg="7">
                            <span class="font-weight-bold name">
                               {{jny.customer_services[0].service_name}}
                            </span>
                          </b-col>
                          <b-col cols="5" sm="5" md="3" lg="3">
                            <span class="text">
                              {{getDateString(jny.created_at)}}
                            </span>
                          </b-col>
                          <b-col cols="3" sm="2" md="2" lg="2">
                            <span class="text" style="float: right;">
                              {{getTime(jny.created_at)}}
                            </span>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-col>
            <b-col cols="1" sm="1"  md="2" lg="2"></b-col>
        </b-row>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import RewardsHeader from "../components/RewardsHeader";
    import router from "../../../../app/router/router";

    export default {
        name: "Rewards",
        components: {RewardsHeader},
        data(){
            return{
              months:["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
              customer:JSON.parse(localStorage.getItem("customer")),
              loading:true,
              selected_id:null
            }
        },
        mounted() {
            this.fetchApprovedJourneys(this.customer.id)
        },
        methods:{
          ...mapActions(["fetchApprovedJourneys"]),
          onSelect(data){
            localStorage.setItem("journey", JSON.stringify(data))
            router.push("/journey-details")
          },
          capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
          },
          goToNewJourney(){
            router.push("/new-journey")
          },
          getDateString(date){
            var sections = date.split("-")
            var d = sections[2].split("T")
            var month_idx = parseInt(sections[1]) - 1
            return d[0]+" / "+this.months[month_idx]+" / "+sections[0];
          },
          getTime(date){
            var sections = date.split("T")
            var sections2 = sections[1].split(".")
            var sections3 = sections[1].split(":")
            var total = parseInt(sections2[0].replaceAll(":", ""))
            if(total > 120000){
              return sections3[0]+":"+sections3[1] + " pm"
            }else{
              return sections3[0]+":"+sections3[1] + " am"
            }
          }
        },
        computed: {
            ...mapGetters([
                "getJourneys",
            ]),
        },
        watch:{
          getJourneys(){
            this.loading = false
          }
        }
    }
</script>

<style scoped>
    @import "../../../../commons/css/main.css";

    .bold{
        font-weight: bold;
    }

    .title{
        margin-top: 80px;
    }

    .text{
      color: #7b7b7b;
    }

    .y-icon{
        margin: 10px;
        height: 90px;
        width: 90px;
    }

    .y-list-group-item{
      padding: 15px 10px;
      border-bottom: solid 1px #bfbfbf;
      margin: 0;
      width: 100%;
    }

    .y-list-group-item:hover{
      background: #f6f6f6;
    }

    .outline-button{
      background: #fff;
      color: #c82333;
      padding: 5px;
      font-weight: bold;
      border: solid 2px #c82333;
      border-radius: 6px;
    }

    .outline-button:hover{
      background: #c82333;
      color: #fff;
    }

    .section-date{
      color: #3d454c;
      font-size: 11px;
    }

    @media only screen and (max-width: 640px){
      .title{
        margin-top: 40px;
      }
      .banner{
        width: 180px;
        margin-top: 10px;
      }

      .text{
        font-size: 12px;
      }

      .name{
        font-size: 14px;
      }

      .section-date{
        font-size: 9px;
      }
    }

    @media only screen and (max-width: 560px){
      .title{
        font-size: 16px;
      }
      .banner{
        width: 150px;
      }

      .text{
        font-size: 11px;
      }

      .name{
        font-size: 12px;
      }
    }

    @media only screen and (max-width: 480px){
      .name{
        font-size: 11px;
      }
      .section-date{
        font-size: 7px;
      }

      .title{
        font-size: 12px;
      }

      .outline-button{
        font-size: 12px;
        border: solid 1px #c82333;
      }

      .banner{
        width: 130px;
      }
    }

    @media only screen and (max-width: 380px) {
      .title{
        font-size: 14px;
      }

    }

    @media only screen and (max-width: 320px) {

    }
</style>