<template>
    <div class="selector">
        <section class="h-100 w-100 d-flex flex-column">
            <b-row>
                <b-col class="header">
                    <img src="../../../../assets/ic_navigate_back.png" class="y-icon-header" alt="back" @click="back">
                </b-col>
            </b-row>
            <div class="d-flex justify-content-md-center align-items-center flex-column flex-grow-1 options">
                <p class="text-center h1 font-weight-bold  mx-auto mt-5 mt-md-0">UGX {{Number(amount).toLocaleString()}}</p>
                <p class="text-center mb-5 ">Choose a payment type below</p>

                <b-col col md="8" lg="7" class="mx-auto">
                    <div class="list-group list-group-flush">
                        <b-row style="background: #dedede; height: 1px; width: 100%; margin: 0; padding: 0;"></b-row>
                        <b-row class="y-list-item" @click="select('cash')">
                            <b-col cols="11" class="text">
                                <div class="align-items-center">
                                    Cash
                                </div>
                            </b-col>
                            <b-col cols="1">
                                <div class="right-figures">
                                    <img src="../../../../assets/ic_next.png" class="y-icon" alt="back">
                                </div>
                            </b-col>
                        </b-row>

                        <b-row class="y-list-item" @click="select('card')">
                            <b-col cols="11" class="text">
                                <div class="align-items-center">
                                    Record Card Payment
                                </div>
                            </b-col>
                            <b-col cols="1">
                                <div class="right-figures">
                                    <img src="../../../../assets/ic_next.png" class="y-icon" alt="back">
                                </div>
                            </b-col>
                        </b-row>

                        <b-row class="y-list-item" @click="select('mobile_money')">
                          <b-col cols="11" class="text">
                            <div class="align-items-center">
                              Mobile Money
                            </div>
                          </b-col>
                          <b-col cols="1">
                            <div class="right-figures">
                              <img src="../../../../assets/ic_next.png" class="y-icon" alt="back">
                            </div>
                          </b-col>
                        </b-row>

                    </div>
                </b-col>
            </div>
        </section>
    </div>
</template>

<script>
    import router from "../../../../app/router/router";
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "PaymentTypeSelector",
        data(){
            return{
              details: JSON.parse(localStorage.getItem("request")),
              amount: localStorage.getItem("req-amount"),
            }
        },
        mounted() {

        },
        methods:{
          ...mapActions(["cancelRequest", "approveRequest"]),
          back(){
            window.history.back()
          },
          select(type){
            if(type == "cash"){
              let data = {
                id: this.details.id,
                amount: this.amount
              }
               this.approveRequest(data)
            }

            if(type == "card"){
              let data = {
                id: this.details.id,
                amount: this.amount
              }
              this.approveRequest(data)
            }

            if(type == "mobile_money"){
              let data = {
                id: this.details.id,
                amount: this.amount
              }
              this.approveRequest(data)
            }
          }
       },

       computed:{
         ...mapGetters(["getRequestSuccessResponse", "getRequestErrorResponse"]),
       },

       watch:{
         getRequestSuccessResponse(){
           localStorage.removeItem("req-amount")
           localStorage.removeItem("request")
           router.push("/confirm-approval")
         }
       }
    }
</script>

<style scoped>
    @import "../../../../commons/css/main.css";

    .selector{

    }

    .header{
        padding: 15px;
    }

    .options{
        margin-top: 80px;
    }

    .y-icon{
        padding: 5px;
        width: 35px;
        margin: 10px;
    }

    .y-icon-header{
        padding: 5px;
        width: 40px;
        margin-top: 10px;
        margin-left: 15px;
    }

    .y-list-item{
        width: 100%;
        font-weight: bold;
        border-bottom: solid 1px #dedede;
        margin: 0px 0px 1px 1px;
        padding: 10px;
    }

    .y-list-item:hover{
        background: rgba(241, 241, 241, 0.55);
    }

    .right-figures{
        margin-top: 10px;
    }

    .text{
        margin: auto;
        padding: 10px;
    }
</style>