<template>
    <div>
        <RewardsHeader title=""/>

        <b-row >
          <b-col cols="2" sm="2" md="5" lg="5"></b-col>
          <b-col cols="8" sm="8" md="2" lg="2">
            <b-row>
              <b-col class="text-center justify-content-center">
                <h3 class="h3 mx-auto bold form-spacing">RECEIPT NO</h3>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="2" sm="2" md="5" lg="5"></b-col>
        </b-row>
        <!--content-->
        <b-row >
          <b-col cols="1" sm="1" md="2" lg="2"></b-col>
          <b-col cols="10" sm="10" md="8" lg="8">
            <b-row>
              <b-col class="text-center">
                <p class="h5 title">Enter Receipt Number</p>
              </b-col>
            </b-row>
            <b-row class="form-spacing">
              <b-col cols="1" sm="1" md="3" lg="3"></b-col>
              <b-col cols="10" md="6" lg="6">
                <b-row class="align-content-center">
                  <b-col>
                    <input type="text"
                           class="input form-control"
                           v-model="receipt"
                           placeholder="Receipt Number"
                           aria-describedby="basic-addon2"/>
                  </b-col>
                </b-row>

                <b-row class="form-spacing">
                  <b-col cols="12" class="text-center">
                    <b-overlay :show="loading" rounded="sm">
                      <button class="button"
                              type="button"
                              @click="onSubmit()">Next</button>
                    </b-overlay>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="1" sm="1" md="3" lg="3"></b-col>
            </b-row>
          </b-col>
          <b-col cols="1" sm="1" md="2" lg="2"></b-col>
        </b-row>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import RewardsHeader from "../components/RewardsHeader";
    import router from "../../../../app/router/router";

    export default {
        name: "ReceiptGivePoints",
        components: {RewardsHeader},
        data(){
            return{
              customer:JSON.parse(localStorage.getItem("customer")),
              redeem_data:JSON.parse(localStorage.getItem("redeem_data")),
              otp_response:JSON.parse(localStorage.getItem("otp-response")),
              receipt:null,
              loading:false
            }
        },
        methods:{
            ...mapActions(["redeemReceipt", "redeemRewardNative"]),
            onSubmit(){
              this.loading = true
              // let data = {
              //   id:this.otp_response.id,
              //   receipt_no: this.receipt
              // }
              this.redeem_data.receipt_no = this.receipt
              this.redeemRewardNative(this.redeem_data)
            }
        },
        computed: {
            ...mapGetters([
                "getReceiptSuccessResponse",
                "getReceiptErrorResponse",
            ]),
        },
        watch:{
            getReceiptSuccessResponse(){
              this.loading = false
              router.push("/complete/redeem-reward")
            },
            getReceiptErrorResponse(data){
              this.loading = false
              alert(data.message)
            }
        }
    }
</script>

<style scoped>
    @import "../../../../commons/css/main.css";

    .bold{
        font-weight: bold;
    }

    .title{
        margin-top: 80px;
    }

    .input{
      width: 100%;
      border-radius: 0;
      font-size: 30px;
      font-weight: bold;
      text-align: center;
      outline: none;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom: dashed 1px #636363;
    }

    input:hover {
      border-top: 0;
      border-left: 0;
      border-right: 0;
      outline: none;
    }

    .button{
      width: 100%;
      height: 60px;
      background: #c82333;
      color: white;
      border: solid 1px #c82333;
      border-radius: 8px;
    }

    .button:hover{
      background: #9c1825;
    }

    .outline-button{
      background: #fff;
      color: #c82333;
      padding: 5px;
      font-weight: bold;
      border: solid 2px #c82333;
      border-radius: 6px;
    }

    .outline-button:hover{
      background: #c82333;
      color: #fff;
    }

    .form-spacing{
      margin-top: 60px;
    }

    @media only screen and (max-width: 640px){
      .input{
        height: 50px;
        font-size: 22px;
      }

    }

    @media only screen and (max-width: 560px){
      .title{
        font-size: 16px;
      }


    }

    @media only screen and (max-width: 480px){
      .title{
        font-size: 12px;
      }

      .input{
        height: 40px;
        font-size: 18px;
      }

      .button{
        height: 40px;
        font-size: 12px;
      }
    }

    @media only screen and (max-width: 380px) {
      .title{
        font-size: 14px;
      }

      .form-spacing{
        margin-top: 80px;
      }

      .h3{
        font-size: 18px;
      }
    }

    @media only screen and (max-width: 320px) {
      .form-spacing{
        margin-top: 30px;
      }
    }
</style>