import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../../views/auth/Login";
import Locations from "../../views/location/Locations";
import GiveOrRedeem from "../../views/home/redeem/GiveOrRedeem";
import Requests from "../../views/home/requests/Requests";
import Apps from "../../views/home/apps/Apps";
import Customer from "../../views/home/redeem/Customer";
import CustomerJourney from "../../views/home/redeem/services/CustomerJourney";
import Rewards from "../../views/home/redeem/services/Rewards";
import SearchReceipt from "../../views/home/requests/services/SearchReceipt";
import ViewRequests from "../../views/home/requests/services/ViewRequests";
import CustomerSignup from "../../views/home/redeem/CustomerSignup";
import BillGivePoints from "../../views/home/redeem/services/BillGivePoints";
import ReceiptRedeem from "../../views/home/redeem/services/ReceiptRedeem";
import RedeemComplete from "../../views/home/redeem/components/RedeemComplete";
import RedeemReward from "../../views/home/redeem/services/RedeemReward";
import NewJourney from "../../views/home/redeem/components/NewJourney";
import JourneyDetails from "../../views/home/redeem/components/JourneyDetails";
import CustomerRequest from "../../views/home/requests/components/CustomerRequest";
import PaymentTypeSelector from "../../views/home/requests/components/PaymentTypeSelector";
import ConfirmApproval from "../../views/home/requests/components/ConfirmApproval";
import RequestGivePoints from "../../views/home/requests/services/RequestGivePoints";
import PointsHistory from "../../views/home/redeem/services/PointsHistory";
import GiveRefundReason from "../../views/home/requests/components/GiveRefundReason";
import ConfirmRefund from "../../views/home/requests/components/ConfirmRefund";
import RedeemHistory from "../../views/home/redeem/services/RedeemHistory";
import RedeemOTP from "../../views/home/redeem/services/RedeemOTP";
import ReceiptGivePoints from "../../views/home/redeem/services/ReceiptRedeem";
import GivingPointsComplete from "../../views/home/redeem/components/GivingPointsComplete";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },

  {
    path: "/locations",
    name: "Locations",
    component: Locations,
  },

  {
    path: "/home/give-or-redeem",
    name: "GiveOrRedeem",
    component: GiveOrRedeem,
  },

  {
    path: "/customer",
    name: "Customer",
    component: Customer,
  },
  {
    path: "/give-points",
    name: "BillGivePoints",
    component: BillGivePoints,
  },

  {
    path: "/rewards",
    name: "Rewards",
    component: Rewards,
  },

  {
    path: "/redeem-item",
    name: "RedeemReward",
    component: RedeemReward,
  },

  {
    path: "/redeem-otp",
    name: "RedeemOTP",
    component: RedeemOTP,
  },

  {
    path: "/search-receipt",
    name: "SearchReceipt",
    component: SearchReceipt
  },

  {
    path: "/points-receipt",
    name: "PointsReceipt",
    component: ReceiptGivePoints
  },

  {
    path: "/customer-signup/:id",
    name: "CustomerSignup",
    component: CustomerSignup
  },

  {
    path: "/view-requests",
    name: "ViewRequests",
    component: ViewRequests
  },

  {
    path: "/points-history",
    name: "PointsHistory",
    component: PointsHistory
  },

  {
    path: "/redeem-history",
    name: "RedeemHistory",
    component: RedeemHistory
  },

  {
    path: "/refund-reason",
    name: "GiveRefundReason",
    component: GiveRefundReason
  },

  {
    path: "/confirm-refund",
    name: "ConfirmRefund",
    component: ConfirmRefund
  },

  {
    path: "/customer-journey",
    name: "CustomerJourney",
    component: CustomerJourney,
  },

  {
    path: "/journey-details",
    name: "JourneyDetails",
    component: JourneyDetails,
  },

  {
    path: "/new-journey",
    name: "NewJourney",
    component: NewJourney,
  },

  {
    path: "/customer-request",
    name: "CustomerRequest",
    component: CustomerRequest,
  },

  {
    path: "/request-points",
    name: "RequestGivePoints",
    component: RequestGivePoints,
  },

  {
    path: "/payment-selector",
    name: "PaymentTypeSelector",
    component:PaymentTypeSelector,
  },

  {
    path: "/confirm-approval",
    name: "ConfirmApproval",
    component: ConfirmApproval,
  },

  {
    path: "/complete/redeem-otp",
    name: "RedeemOTP",
    component: RedeemOTP,
  },

  {
    path: "/complete/redeem/receipt-no",
    name: "RequestGivePoints",
    component: ReceiptRedeem,
  },

  {
    path: "/complete/redeem-reward",
    name: " RedeemComplete",
    component: RedeemComplete,
  },

  {
    path: "/complete/points",
    name: "GivingPointsComplete",
    component: GivingPointsComplete,
  },

  {
    path: "/home/requests",
    name: "Requests",
    component: Requests,
  },

  {
    path: "/home/apps",
    name: "Apps",
    component: Apps,
  },

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
