import Vue from "vue";
import Vuex from "vuex";
import login from "../../views/auth/vuex-store/login";
import locations from "../../views/location/vuex-store/locations";
import customer from "../../views/home/redeem/vuex-store/customer";
import redeem from "../../views/home/redeem/vuex-store/redeem";
import requests from "../../views/home/requests/vuex-store/requests";
import journeys from "../../views/home/redeem/vuex-store/journeys";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    login,
    locations,
    customer,
    redeem,
    requests,
    journeys
  }
});
