<template>
    <div>
        <b-row class="header">
          <b-col cols="1">
            <img @click="back()" src="../../../../assets/ic_navigate_back.png" class="y-icon" alt="back">
          </b-col>
          <b-col cols="11">
            <b-row>
              <b-col cols="4"></b-col>
              <b-col cols="3" class="heading">
                <b-row>
                  <b-col class="text-center justify-content-center">
                    <h3 class="h3 mx-auto bold title">GIVE POINTS</h3>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="3">
                <img src="../../../../assets/services/service_give_points.svg" class="banner"/>
              </b-col>
              <b-col cols="2"></b-col>
            </b-row>
          </b-col>
        </b-row>
        <!--content-->
        <b-row>
            <b-col cols="1"></b-col>
            <b-col cols="10">
              <b-row>
                <b-col cols="12" class="text-center justify-content-center">
                  <p class="h5 title">Customer's Total Bill</p>
                </b-col>
              </b-row>
              <b-row class="form-spacing">
                <b-col cols="4"></b-col>
                <b-col cols="4">
                  <b-row class="align-content-center fill-width-parent">
                    <b-col>
                      <input type="text"
                             class="input text-center"
                             v-model="raw_amount"
                             placeholder="Total Bill"/>
                    </b-col>
                  </b-row>

                  <b-row class="mt-5" style="width: 100%">
                    <b-col cols="12" class="text-center">
                      <b-overlay :show="loading" rounded="sm">
                        <button class="button"
                                type="button"
                                @click="onSubmit()">Give</button>
                      </b-overlay>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="4"></b-col>
              </b-row>
            </b-col>
            <b-col cols="1"></b-col>
        </b-row>

        <b-row class="bottom">
          <b-col  cols="12" class="text-center justify-content-center align-content-center py-2">
            <b-row>
              <b-col>
                <span class="footer-text">Powered By</span>
                <img src="../../../../assets/logo_small_size.png" class="footer-icon"/>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import router from "../../../../app/router/router";

    export default {
        name: "BillGivePoints",
        data(){
            return{
              customer:JSON.parse(localStorage.getItem("customer")),
              details: JSON.parse(localStorage.getItem("request")),
              amount:null,
              raw_amount:null,
              loading:false
            }
        },
      mounted() {
          this.raw_amount =  this.details.amount
      },
      methods:{
            ...mapActions(["givePoints"]),
            onSubmit(){
              // this.loading = true
              // let data = {
              //   customer_id:this.customer.id,
              //   amount:this.amount
              // }
              // this.givePoints(data)
              localStorage.setItem("req-amount", this.amount)
              router.push("/payment-selector")
            },
            goToHistory(){
              router.push("/points-history")
            },
            back(){
              window.history.back();
            }
        },
        computed: {
            ...mapGetters([
                "getPointsSuccessResponse",
                "getPointsErrorResponse",
            ]),
            getAmount(){
              return this.raw_amount
            }
        },
        watch:{
            getPointsSuccessResponse(){
              this.loading = false

            },
            getPointsErrorResponse(data){
              this.loading = false
              alert(data.message)
            },
            getAmount(data){
              this.amount =  data.replaceAll(",", "")
              this.raw_amount = Number(this.amount).toLocaleString()
            }
        }
    }
</script>

<style scoped>
    @import "../../../../commons/css/main.css";

    .header{
      width: 100%;
      padding: 5px 10px;
    }

    .heading{
      margin-top: 80px;
    }

    .y-icon{
      padding: 5px;
      width: 45px;
      margin: 10px;
    }

    .y-icon:hover{
      padding: 6px;
      background: #f3f3f3;
      border-radius: 6px;
    }

    .banner{
      width: 80%;
    }

    .bold{
        font-weight: bold;
    }

    .title{
        margin-top: 10px;
    }

    .input{
      width: 100%;
      height: 60px;
      font-size: 25px;
      font-weight: bold;
      padding: 0;
      outline: none;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom: dashed 1px #636363;
    }

    input:hover {
      border-top: 0;
      border-left: 0;
      border-right: 0;
      outline: none;
    }

    input:focus{
      border-top: 0;
      border-left: 0;
      border-right: 0;
      outline: none;
    }

    .button{
      width: 100%;
      height: 60px;
      background: #c82333;
      color: white;
      border: solid 1px #c82333;
      border-radius: 8px;
    }

    .button:hover{
      background: #9c1825;
    }

    .outline-button{
      background: #fff;
      color: #c82333;
      padding: 5px;
      font-weight: bold;
      border: solid 2px #c82333;
      border-radius: 6px;
    }

    .outline-button:hover{
      background: #c82333;
      color: #fff;
    }

    .form-spacing{
      margin-top: 20px;
    }

    .bottom{
      position: fixed;
      bottom: 0;
      width: 100%;
    }

    .footer-icon{
      height: 20px;
      width: 60px;
      margin-left: 10px;
    }

    .footer-text{
      font-size: 14px;
    }
</style>