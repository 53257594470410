<template>
    <div class="requests">
      <RequestsHeader title=""/>
      <b-row class="top-padding">
        <b-col cols="12" class="text-center align-content-center">
          <div class="font-weight-bold h4 title">VIEW REQUESTS</div>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6" sm="6" md="9" lg="9"></b-col>
        <b-col cols="6" sm="6" md="2" lg="2">
          <b-row style="width: 100%;">
            <date-range-picker
                class="select"
                ref="picker"
                :locale-data="{format: 'dd-mm-yyyy' }"
                :minDate="null" :maxDate="null"
                :singleDatePicker="false"
                :timePicker="false"
                :timePicker24Hour="false"
                :showWeekNumbers="true"
                :showDropdowns="true"
                :autoApply="true"
                v-model="date_range"
                :linkedCalendars="true">
                <template style="width: 100%;">
                      <span style="font-size: 11px;">
                         {{ date_range.startDate }} - {{ date_range.endDate}}
                      </span>
                </template>
            </date-range-picker>
          </b-row>
        </b-col>
        <b-col cols="0" sm="0" md="1" lg="1"></b-col>
      </b-row>

      <b-row>
        <b-col cols="0" sm="0" md="1" lg="1"></b-col>
        <b-col cols="12" sm="12" md="10" lg="10" class="top-padding content">
          <b-overlay :show="loading" rounded="sm">
            <b-row>
              <b-col cols="12">
                <div  class="flex-grow-1">
                  <div class="list-group" v-for="(request, idx) in getRequests" :key="idx">
                    <b-row class="separator">
                      <b-col cols="5">
                        <span class="list-header-label font-weight-bold">
                          {{getDateString(request.date)}}
                        </span>
                      </b-col>
                      <b-col cols="2">
                        <div style="float: right;">
                          <span class="list-header-label font-weight-bold">
                             Status
                          </span>
                        </div>
                      </b-col>
                      <b-col cols="3">
                        <div style="float: right;">
                          <span class="list-header-label font-weight-bold">
                             Amount
                          </span>
                        </div>
                      </b-col>
                      <b-col cols="2">
                        <div style="float: right;">
                          <span class="list-header-label font-weight-bold">
                            Time
                          </span>
                        </div>
                      </b-col>
                    </b-row>

                    <b-row :class="[req.id == selected_id ? 'y-list-group-item-active' : 'y-list-group-item']"
                           v-for="(req, idx) in request.journeys"
                           :key="idx" @click="onSelect(req)">
                      <b-col cols="5">
                        <b-row>
                          <b-col cols="4">
                            <span class="text font-weight-bold wrap">
                              #{{req.receipt_no}}
                            </span>
                          </b-col>
                          <b-col cols="8">
                            <span class="text">
                              {{req.name}}
                            </span>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col cols="2">
                        <div style="float: right;">
                          <span :class="[(req.journey_status == 'Approved') ? 'status-complete' : (req.journey_status == 'Cancelled') ? 'status-canceled' : 'text']">
                            {{req.journey_status}}
                          </span>
                        </div>
                      </b-col>
                      <b-col cols="3">
                        <div style="float: right;">
                          <span class="text" >
                            UGX.{{Number(req.amount).toLocaleString()}}
                          </span>
                        </div>

                      </b-col>
                      <b-col cols="2">
                        <div style="float: right;">
                          <span class="text">
                            {{getTime(req.created_at)}}
                          </span>
                        </div>


                      </b-col>
                    </b-row>
                  </div>
                  <b-row v-if="getRequestsPaginator" style="margin-top: 20px;">
                    <b-col cols="1">
                      <button class="button" @click="prevPage">Prev</button>
                    </b-col>
                    <b-col cols="1" class="text-center">
                      <p class="pages">{{getRequestsPaginator.current_page}} / {{getRequestsPaginator.last_page}}</p>
                    </b-col>
                    <b-col cols="1">
                      <button class="button" @click="nextPage">Next</button>
                    </b-col>
                    <b-col cols="9"></b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </b-overlay>
        </b-col>
        <b-col cols="0" sm="0" md="1" lg="1"></b-col>
      </b-row>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import RequestsHeader from "../components/RequestsHeader";
    import DateRangePicker from 'vue2-daterange-picker'
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
    import router from "../../../../app/router/router";

    export default {
        name: "ViewRequests",
        components:{RequestsHeader, DateRangePicker},
        data(){
            return{
              months:["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
              loading: true,
              date_range:{
                startDate:null,
                endDate:null
              }
            }
        },
      mounted() {
          this.fetchRequests()
      },
      methods:{
        ...mapActions(["fetchRequests", "fetchRequestsPaged"]),
        onSelect(data){
          localStorage.setItem("request", JSON.stringify(data))
          router.push("/customer-request")
        },
        getDateString(date){
          var sections = date.split("-")
          var d = sections[2].split("T")
          var month_idx = parseInt(sections[1]) - 1
          return d[0]+" "+this.months[month_idx]+", "+sections[0];
        },
        getTime(date){
          var sections = date.split("T")
          var sections2 = sections[1].split(".")
          var sections3 = sections[1].split(":")
          var total = parseInt(sections2[0].replaceAll(":", ""))
          if(total > 120000){
            return sections3[0]+":"+sections3[1] + " pm"
          }else{
            return sections3[0]+":"+sections3[1] + " am"
          }
        },
        prevPage(){
          if(parseInt(this.getRequestsPaginator.current_page) - 1 > 0){
            let data = {
              page:parseInt(this.getRequestsPaginator.current_page) - 1
            }
            this.loading = true
            this.fetchRequestsPaged(data)
          }
        },
        nextPage(){
          if(parseInt(this.getRequestsPaginator.current_page) + 1 <= parseInt(this.getRequestsPaginator.last_page)){
            let data = {
              page:parseInt(this.getRequestsPaginator.current_page) + 1
            }
            this.loading = true
            this.fetchRequestsPaged(data)
          }
        }
      },
      computed:{
        ...mapGetters(["getRequests", "getRequestsPaginator"]),
      },

      watch: {
        getRequests() {
          this.loading = false
        }
      },

    }
</script>

<style scoped>
    @import "../../../../commons/css/main.css";
    @import "../../../../commons/css/menu.css";
    @import "../../../../commons/css/utils.css";

    .requests{
        overflow: hidden;
    }

    .content{
      min-height: 100px;
    }

    .top-padding{
      margin-top: 40px;
    }

    .separator{
      background: #ededed;
      padding: 15px 10px;
      margin: 0;
      width: 100%;
    }

    .select{
      width: 100%;
    }

    .y-list-group-item{
      padding: 15px 10px;
      border-bottom: solid 1px #bfbfbf;
      margin: 0;
      width: 100%;
    }

    .y-list-group-item-active{
      padding: 15px 10px;
      background: #b42c38;
      color: white;
      margin: 0;
      width: 100%;
    }

    .y-list-group-item:hover{
      background: #db3644;
      color: white;
    }

    .status-canceled{
      color: #bd2130;
      font-weight: bold;
    }

    .status-complete{
      color: #1e7e34;
      font-weight: bold;
    }

    .button{
      width: 100%;
      background: #c82333;
      font-size: 12px;
      padding: 5px;
      color: white;
      border: solid 1px #c82333;
      border-radius: 3px;
    }

    .button:hover{
      background: #a80312;
    }

    @media only screen and (max-width: 570px) {
      .title{
        font-size: 16px;
      }

      .wrap{
        text-overflow: ellipsis " [..]";
        overflow: hidden;
      }

      .wrap:hover {
        overflow: visible;
      }

    }

    @media only screen and (max-width: 380px) {
      .title{
        font-size: 14px;
      }

      .list-header-label{
        font-size: 8px;
      }

      .text{
        font-size: 8px;
      }

      .time{
        font-size: 6px;
      }

      .status-canceled{
        font-size: 8px;
      }

      .status-complete{
        font-size: 8px;
      }

    }

    @media only screen and (max-width: 320px) {

    }

</style>