<template>
    <div>
        <b-row class="header">
            <b-col cols="9" sm="9" md="11" lg="11">
              <a class="navbar-brand ml-3">
                <img @click="cancel" src="../../../assets/ic_cancel.png" class="y-icon-header">
              </a>
              <div class="flex-grow-1 d-md-flex align-items-center justify-content-center d-none">

              </div>
            </b-col>
            <b-col cols="3" sm="3" md="1" lg="1" class="m-auto">
              <button @click="onSave" class="button">Save</button>
            </b-col>
        </b-row>

        <b-row class="content d-flex justify-content-center align-items-center flex-column">
            <b-col cols="2" sm="2" md="4" lg="4"></b-col>
            <b-col cols="8" sm="8" md="4" lg="4">
                <b-overlay :show="loading" rounded="sm">
                    <b-row class="space">
                        <b-col class="text-center">
                            <h3 class="text-center font-weight-bold h3">Enter Customer Details</h3>
                        </b-col>
                    </b-row>
                    <b-row class="field space">
                        <b-col cols="12">
                            <b-row>
                                <span class="label">First Name *</span>
                            </b-row>
                            <b-row>
                                <input v-model="form.fname" type="text" class="input">
                            </b-row>
                        </b-col>
                    </b-row>

                    <b-row class="field space">
                        <b-col cols="12">
                            <b-row>
                                <span class="label">Last Name *</span>
                            </b-row>
                            <b-row>
                                <input v-model="form.lname" type="text" class="input">
                            </b-row>
                        </b-col>
                    </b-row>

                    <b-row class="field space" v-if="settings.email">
                        <b-col cols="12">
                            <b-row>
                                <span class="label">Email *</span>
                            </b-row>
                            <b-row>
                                <input v-model="form.email" type="email" class="input">
                            </b-row>
                        </b-col>
                    </b-row>

                    <b-row class="field space" v-if="settings.date_of_birth">
                        <b-col cols="12">
                            <b-row>
                                <span class="label">Date of Birth *</span>
                            </b-row>
                            <b-row>
                                <input v-model="form.dob" type="date" class="input">
                            </b-row>
                        </b-col>
                    </b-row>

                    <b-row class="field space">
                        <b-col cols="12">
                            <b-row>
                                <span class="label">Gender *</span>
                            </b-row>
                            <b-row>
                                <select v-model="form.gender" type="text" class="input">
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>
                            </b-row>
                        </b-col>
                    </b-row>

                    <b-row class="field space">
                        <b-col cols="12">
                            <b-row>
                                <span class="label">Address</span>
                            </b-row>
                            <b-row>
                                <input v-model="form.address" type="text" class="input">
                            </b-row>
                        </b-col>
                    </b-row>
                </b-overlay>
            </b-col>
            <b-col cols="2" sm="2" md="4" lg="4"></b-col>
        </b-row>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import router from "../../../app/router/router";
    export default {
        name: "CustomerSignup",
        data(){
            return{
                settings: JSON.parse(localStorage.getItem("business")).settings,
                form:{
                    fname:null,
                    lname:null,
                    email:null,
                    dob:null,
                    gender:null,
                    phone:null,
                    address:null
                },
                loading: false
            }
        },
        mounted() {
            this.form.phone = window.location.href.split('/')[4]
        },
        methods:{
            ...mapActions(["createCustomer", "checkout"]),
            onSave(){
                this.loading = true
                this.createCustomer(this.form)
            },
            cancel(){
                window.history.back();
            },
        },
        computed: {
            ...mapGetters([
                "getCustomerSuccessResponse",
                "getCustomerErrorResponse",
                "getCheckoutSuccessResponse",
                "getCheckoutErrorResponse",
            ]),
        },
        watch:{
            getCustomerSuccessResponse(){
                this.loading = true
                this.checkout(this.form.phone)
            },
            getCustomerErrorResponse(data){
                alert(data.error)
            },
            getCheckoutSuccessResponse(data){
                this.loading = false
                localStorage.setItem("customer", JSON.stringify(data))
                router.push("/customer")
            },
            getCheckoutErrorResponse(data){
                alert(data.error)
                router.push("/customer-signup/"+this.phone)
            }
        }
    }
</script>

<style scoped>
    @import "../../../commons/css/main.css";

    .header{
      margin: 0;
      padding: 0;
      width: 100%;
      height: 85px;
      z-index: 40;
      box-shadow: 0px 5px 3px #dedede;
      background: white;
    }

    .content{
        margin-top: 6%;
    }

    .y-icon-header{
        padding: 5px;
        width: 38px;
        height: 38px;
        margin: 16px 10px 10px 10px
    }

    .y-icon-header:hover{
        padding: 6px;
        background: #f3f3f3;
        border-radius: 6px;
    }

    .input{
        width: 100%;
        border: 0 none;
    }

    .input:focus{
        border: 0 none;
    }

    .input:active{
        border: 0 none;
    }

    input:focus, textarea:focus, select:focus{
        outline: none;
    }

    .label{
        color: #a0a0a0;
    }

    .field{
        border: solid 1px #777777;
        border-radius: 6px;
        width: 100%;
        padding: 10px 20px;
    }

    .space{
        margin-top: 40px;
    }

    .right{
        float: right;
    }

    .button{
      width: 100%;
      background: #c82333;
      color: white;
      border: solid 1px #c82333;
      border-radius: 3px;
      font-size: 14px;
      padding: 10px;
    }


    @media only screen and (max-width: 960px) {

    }

    @media only screen and (max-width: 780px) {

    }

    @media only screen and (max-width: 640px) {
      .y-icon-header{
        width: 32px;
        height: 32px;
        margin-top: 20px;
      }
    }

    @media only screen and (max-width: 560px) {

    }

    @media only screen and (max-width: 480px) {


    }

    @media only screen and (max-width: 380px) {

    }

    @media only screen and (max-width: 320px) {

    }

</style>