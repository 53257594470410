<template>
    <div class="header">
        <b-row>

            <b-col cols="1" sm="2" md="3" lg="3" xl="4">
                <img @click="back()" src="../../assets/ic_navigate_back.png" class="y-icon" alt="back">
            </b-col>
            <b-col cols="10" sm="8" md="6" lg="6" xl="4" style="overflow: hidden; text-align: center;">
                <h4 class="title">{{title}}</h4>
            </b-col>
            <b-col cols="1" sm="2" md="3" lg="3" xl="4"/>
        </b-row>
    </div>
</template>

<script>
    export default {
        name: "LocationHeader",
        props:{
            title:String
        },
        methods:{
            back(){
                alert("You are already logged in.")
            }
        },
    }
</script>

<style scoped>
    .header{
        width: 100%;
        padding: 5px 10px;
        box-shadow: 0px 5px 3px #dedede;
    }

    .y-icon{
        padding: 5px;
        width: 45px;
        margin: 10px;
    }

    .y-icon:hover{
        padding: 6px;
        background: #f3f3f3;
        border-radius: 6px;
    }

    .title{
        color: #3d454c;
        display: inline-block;
        padding: 10px;
        margin-top: 5px;
        font-weight: bold;
    }


    @media only screen and (max-width: 1281px) {

    }

    @media only screen and (max-width: 1199px) {


    }

    @media only screen and (max-width: 800px) {
      .y-icon{
        width: 35px;
      }

    }

    @media only screen and (max-width: 640px) {
      .title{
        font-size: 18px;
        margin-top: 10px;
      }
    }

    @media only screen and (max-width: 570px) {
        .y-icon{
            width: 30px;
        }

        .title{
            margin-top: 10px;
        }

    }

    @media only screen and (max-width: 380px) {
        .y-icon{
            padding: 5px;
            width: 32px;
            margin-top: 14px;
        }

        .title{
            font-size: 16px;
            margin-top: 10px;
        }
    }

    @media only screen and (max-width: 320px) {

    }
</style>